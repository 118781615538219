
import {
    Shield32Regular,
    Filter24Regular,
    FilterDismiss24Regular,
    Next24Regular,
    CheckmarkUnderlineCircle24Regular,
    Next48Regular,
    CalendarAdd24Regular,
    Info20Regular,
    Edit24Filled,
    Delete24Filled,
    MoreHorizontal24Filled,
    ChevronDown24Filled,
    ChevronUp24Filled,
    Checkmark20Filled,
    Dismiss20Filled,
    Add24Filled,
    Warning32Filled,
    DismissCircle32Regular,
    Info32Regular,
    Checkmark48Filled,
    ArrowClockwise20Regular,
    DocumentTableSearch20Filled
} from "@fluentui/react-icons";
import './icons.css'

export const InfoIcon = () => {
    return <Info20Regular />
}
export const PencilIcon = () => {
    return <Edit24Filled />
}
export const TrashIcon = () => {
    return <Delete24Filled />
}
export const MoreHorizontalIcon = () => {
    return <MoreHorizontal24Filled />
}
export const CloseIcon = () => {
    return <Dismiss20Filled />
}
export const AddIcon = () => {
    return <Add24Filled />
}
export const CheckIcon = () => {
    return <Checkmark20Filled />
}
export const ArrowUpIcon = () => {
    return <ChevronUp24Filled />
}
export const ArrowDownIcon = () => {
    return <ChevronDown24Filled />
}
export const ResetIcon = () => {
    return <ArrowClockwise20Regular style={{ marginLeft: "5px" }} />
}
export const WarningIcon = () => {
    return <Warning32Filled />
}
export const ErrorIcon = () => {
    return <DismissCircle32Regular />
}
export const InfoIconLarge = () => {
    return <Info32Regular />
}

export const NextIcon = () => {
    return <Next24Regular />
}
export const FilterIcon = () => {
    return <Filter24Regular />
}
export const FilterResetIcon = () => {
    return <FilterDismiss24Regular />
}
export const ShieldIcon = () => {
    return <Shield32Regular />
}
export const CheckIconLarge = () => {
    return <Checkmark48Filled />
}
export const NextSitzungIconLarge = () => {
    return <Next48Regular />
}
export const LastSitzungIcon = () => {
    return <CheckmarkUnderlineCircle24Regular />
}
export const LastSitzungIconLarge = () => {
    return <CheckmarkUnderlineCircle24Regular className="large-icon" />
}
export const AddEventIcon = () => {
    return <CalendarAdd24Regular />
}
export const MeetingLinksIcon = () => {
    return <DocumentTableSearch20Filled />
}