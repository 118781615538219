import { Dialog, DialogContent, DialogSurface, Text, Spinner } from "@fluentui/react-components";
import * as microsoftTeams from "@microsoft/teams-js";
import { Guid } from "guid-typescript";
import { StatusCodes } from "http-status-codes";
import * as React from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { KanalLimitMessage, LoaderMessage } from "../../constants/textLabels";
import { getHome } from "../../api/home-api";
import Constants from "../../constants/constants";
import { Sitzungsart } from "../../enums/sitzungsart.enum";
import { Sitzungsformat } from "../../enums/sitzungsformat.enum";
import { IWithContext } from "../../providers/context-provider";
import MeetingForm from "./sitzung-form/meeting-form";
import "./sitzungen.scss";
import IPermission from "../../models/IPermission";
import { getPermissions, getPermissionsHasAppAccess } from "../../api/permission-api";
import PermissionsContext from "../context/permissions-context";
import { userHasPermission } from "../../Helpers/permissionHelper";
import { mitgliederFullyInserted } from "../../Helpers/mitgliederMembersHelper";
import SitzungTable from "../common/sitzung-table/sitzung-table";
import { getAllMeetings } from "../../api/meeting-dto-api";
import { getGremiumDetailDto } from "../../api/konfig-tagesordnungspunktvorlage-api";
import { getChannelsCount } from "../../api/sitzungen-api";
import AlertDialog from "../common/dialog/alert-dialog";
import { getAllGremiumKonfigs } from "../../api/konfiguration-api";
import UnauthorizedPage from "../unauthorized-page/unauthorized-page";
import { contextType } from "react-quill";

interface ISitzungenState {
  isActionEnabled: boolean;
  isAllDashboardRequestsSelected: boolean;
  isLoading: boolean;
  isMobileView: boolean;
  isApproveDialogOpen: boolean;
  isRejectDialogOpen: boolean;
  isSelectMultiple: boolean;
  searchText: string;
  pageNumber: number;
  isStart: boolean;
  isEnd: boolean;
  isLoggedInUserManager: boolean;
  managersComment: string;
  isReasonInputValid: boolean;
  rows: any[];
  rowsWithoutFilter: any[];
  isMeetingFormOpen: boolean;
  sitzungFormTabNumber: number;
  nextButtonRef;
  backButtonRef;
  sitzungId: string;
  lastSitzungId: string;
  nextSitzungId: string;
  nachteSitzungPermissions: IPermission[];
  letzteSitzungPermissions: IPermission[];
  sitzungFormPermissions: IPermission[];
  councilMembersInserted;
  tableRows: any[];
  showTeamCountAlert: boolean
}



interface ISitzungenProps
  extends WithTranslation,
  IWithContext,
  RouteComponentProps { }
// Renders error page with generic error message.
class Sitzungen extends React.Component<ISitzungenProps, ISitzungenState> {
  /**
   * Constructor which initializes state.
   */
  static contextType = PermissionsContext
  context!: React.ContextType<typeof PermissionsContext>
  constructor(props: any) {
    super(props);
    this.state = {
      isApproveDialogOpen: false,
      isRejectDialogOpen: false,
      isAllDashboardRequestsSelected: false,
      isActionEnabled: false,
      isLoading: false,
      isMobileView: window.outerWidth <= Constants.maxWidthForMobileView,
      isSelectMultiple: false,
      searchText: "",
      pageNumber: 0,
      isStart: false,
      isEnd: false,
      isLoggedInUserManager: false,
      managersComment: "",
      isReasonInputValid: true,
      rows: [],
      rowsWithoutFilter: [],
      isMeetingFormOpen: false,
      sitzungFormTabNumber: 0,
      nextButtonRef: React.createRef(),
      backButtonRef: React.createRef(),
      sitzungId: Guid.EMPTY,
      lastSitzungId: Guid.EMPTY,
      nextSitzungId: Guid.EMPTY,
      nachteSitzungPermissions: [],
      letzteSitzungPermissions: [],
      sitzungFormPermissions: [],
      councilMembersInserted: false,
      tableRows: [],
      showTeamCountAlert: false
    };
  }

  /**
   * Called when component unmount.
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.onScreenSizeChange);
  }

  /**
   * Called when component mount.
   */
  async componentDidMount() {
    microsoftTeams.initialize();
    window.addEventListener("resize", this.onScreenSizeChange);
    await this.loadSitzungenAsync();
    this.getLastAndNextSitzungen();

    if (!localStorage.getItem("selectedAuschuss")) {
      const responsePermissions = await getPermissionsHasAppAccess(
        Guid.createEmpty(),
        Guid.createEmpty(),
        this.handleTokenAccessFailure
      );
      if (responsePermissions.data[10].value) {
        const response = await getAllGremiumKonfigs(this.handleTokenAccessFailure)
        const filterGremium = response.data.filter((g) => g.parentGremiumId === null)
        localStorage.setItem("selectedAuschuss", filterGremium[0].id)
      }
    }
    const councilMembersInserted = await mitgliederFullyInserted();
    this.setState({
      councilMembersInserted: councilMembersInserted,
    });
  }

  loadSitzungSpecificPermissions = async () => {
    //Nachte Sitzung permissions
    if (
      this.state.nextSitzungId &&
      this.state.nextSitzungId.toString() !== Guid.EMPTY
    ) {
      const responseNachte = await getPermissions(
        this.state.nextSitzungId,
        Guid.createEmpty(),
        this.handleTokenAccessFailure
      );
      if (responseNachte.status === StatusCodes.OK && responseNachte.data) {
        this.setState({ nachteSitzungPermissions: responseNachte.data });
      }
    }
    //Letzte Sitzung permissions
    if (
      this.state.lastSitzungId &&
      this.state.lastSitzungId.toString() !== Guid.EMPTY
    ) {
      const responseLetzte = await getPermissions(
        this.state.lastSitzungId,
        Guid.createEmpty(),
        this.handleTokenAccessFailure
      );
      if (responseLetzte.status === StatusCodes.OK && responseLetzte.data) {
        this.setState({ letzteSitzungPermissions: responseLetzte.data });
      }
    }
  };

  userHasReadingPermissionsForParticularMeeting = async (meetingId: Guid) => {
    const response = await getPermissions(
      meetingId,
      Guid.createEmpty(),
      this.handleTokenAccessFailure
    );
    if (response.status === StatusCodes.OK && response.data) {
      const hasPermission = userHasPermission(response.data, "SitzungRead");
      if (hasPermission === true) {
        this.setState({
          sitzungFormPermissions: response.data,
        });
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  /**
   * Called when screen size gets updated; which sets the state to indicate whether mobile view enabled.
   */
  onScreenSizeChange = () => {
    this.setState({
      isMobileView: window.outerWidth <= Constants.maxWidthForMobileView,
    });
  };

  openAddNewSitzungTaskModule = () => {
    this.props.history.push("/nachste-sitzung");
  };

  openNeueSitzungModal = async () => {
    const response = await getGremiumDetailDto(localStorage.getItem('selectedAuschuss')!, this.handleTokenAccessFailure)
    const channelsCount = await getChannelsCount(response.data.defaultTeamId, this.handleTokenAccessFailure)
    if (channelsCount.data >= 950) {
      this.setState({ showTeamCountAlert: true })
    }
    this.setState({
      isMeetingFormOpen: true,
      sitzungId: Guid.EMPTY,
      sitzungFormPermissions: this.context.permissionsArray,
    });

  };

  openLetzteSitzungModal = () => {
    this.setState({
      isMeetingFormOpen: true,
      sitzungId: this.state.lastSitzungId,
      sitzungFormPermissions: this.state.letzteSitzungPermissions,
    });
  };

  openNachteSitzungModal = () => {
    this.setState({
      isMeetingFormOpen: true,
      sitzungId: this.state.nextSitzungId,
      sitzungFormPermissions: this.state.nachteSitzungPermissions,
    });
  };

  openSitzungModal = async (sitzungId) => {
    this.userHasReadingPermissionsForParticularMeeting(sitzungId).then(
      (result) => {
        if (result === true) {
          this.setState({
            isMeetingFormOpen: true,
            sitzungId: sitzungId,
          });
        }
      }
    );
  };

  closeSitzungModal = async () => {
    await this.loadSitzungenAsync();
    await this.setState({ isMeetingFormOpen: false });
  };

  loadSitzungenAsync = async () => {
    this.setState({ isLoading: true });
    //let response = await getSitzungen(this.handleTokenAccessFailure);
    let response = await getAllMeetings();
    if (response.status === StatusCodes.OK && response.data) {
      response.data = response.data.map((item) => ({
        ...item,
        datum: new Date(item.datum),
        sitzungsartstring:
          item.sitzungsart === Sitzungsart.Ordentlich
            ? "Ordentliche Sitzung"
            : "Außerordentliche Sitzung",
        sitzungsformatstring:
          item.sitzungsformat === Sitzungsformat.Präsenz
            ? "Präsenz"
            : item.sitzungsformat === Sitzungsformat.Online
              ? "Online"
              : "Hybrid",
      }));
      this.setState(
        { rows: response.data, rowsWithoutFilter: response.data },
        this.initTableRows
      );
    } else {
      this.setState({ isLoading: false });
    }
  };

  initTableRows = () => {
    let tableRows = this.state.rows?.map((row, i) => {
      return {
        rowData: row,
        key: i,
        onClick: () => this.openSitzungModal(row.id),
        item: {
          sitzungDate: row.start.slice(0, 10),
          sitzungName: `${row.start.slice(0, 10)} ${row.name}`,
          sitzungArt: row.sitzungsartstring,
          sitzungFormat: row.sitzungsformatstring,
        },
      };
    });

    this.setState({
      tableRows: tableRows,
      isLoading: false,
    });
  };

  getLastAndNextSitzungen = async () => {
    let response = await getHome(this.handleTokenAccessFailure);
    if (response.status === StatusCodes.OK && response.data) {
      //this.setState(response.data);
      this.setState(
        {
          lastSitzungId: response.data.letzteSitzung?.id,
          nextSitzungId: response.data.nächsteSitzung?.id,
        },
        this.loadSitzungSpecificPermissions
      );
    }
  };

  handleTokenAccessFailure = (error: string) => {
    this.props.history.push("/signin");
  };

  /**
   * Renders the component
   */
  render = () => {
    return (
      userHasPermission(this.context.permissionsArray, "GremiumRead") ?
        <div className="container-div sitzungen-container">
          <h2>Alle Sitzungen</h2>
          <div>
            <Text
            >Hier sehen Sie alle Sitzungen im Überblick und können nach Datum etc. sortieren und filtern.</Text>
          </div>
          <AlertDialog
            isDialogHidden={!this.state.showTeamCountAlert}
            message={KanalLimitMessage}
            closeFunction={() => { this.setState({ showTeamCountAlert: false }) }} />
          {this.state.isMeetingFormOpen ? !this.state.showTeamCountAlert &&
            <div className="dialog-background">
              <Dialog
                modalType="non-modal"
                //className='sitzung-form__dialog'
                open={this.state.isMeetingFormOpen}>
                <DialogSurface style={{ minWidth: "90%", height: "90vh", overflow: "scroll" }}>
                  <DialogContent>
                    <MeetingForm
                      gremiumId={this.context?.auschuss?.gremiumId!}
                      closeDialog={this.closeSitzungModal}
                      meetingId={this.state.sitzungId}
                      allSitzung={this.state.rows}
                      userPermissions={this.state.sitzungFormPermissions}
                    />
                  </DialogContent>
                </DialogSurface>
              </Dialog>
            </div>
            :
            <div className="margins sitzung-table__holder">
              {this.state.isLoading ? (
                <Spinner className="loader" label={LoaderMessage} />
              ) : (
                <SitzungTable
                  key={this.state.sitzungId}
                  tableRows={this.state.tableRows}
                  hasPermissionToCreate={userHasPermission(
                    this.context.permissionsArray,
                    "SitzungCreate"
                  )}
                  openNewSitzung={this.openNeueSitzungModal}
                  openLastSitzung={this.openLetzteSitzungModal}
                  openNextSitzung={this.openNachteSitzungModal}
                  canOpenNewSitzung={
                    userHasPermission(
                      this.context.permissionsArray,
                      "SitzungCreate"
                    ) && this.state.councilMembersInserted
                  }
                  canOpenNextSitzung={
                    this.state.nextSitzungId != null &&
                    userHasPermission(
                      this.state.nachteSitzungPermissions,
                      "SitzungRead"
                    )
                  }
                  canOpenLastSitzung={
                    this.state.lastSitzungId != null &&
                    userHasPermission(
                      this.state.letzteSitzungPermissions,
                      "SitzungRead"
                    )
                  }
                  councilMembersInserted={this.state.councilMembersInserted}
                />
              )}
            </div>
          }
        </div> : <UnauthorizedPage />
    );
  };
}

export default Sitzungen;
