import moment from "moment";
import {
  SitzungItem,
  TableColumnType,
} from "../../../interfaces/sitzungen/sitzungen-table.interface";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  useTableFeatures,
  TableColumnDefinition,
  TableColumnId,
  useTableSort,
  TableCellLayout,
  createTableColumn,
  Input,
  Dropdown,
  Option,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { FilterIcon } from "../../../utils/icons";

const artDerSitzungOptions = [
  {
    id: 0,
    label: "",
  },
  {
    id: 1,
    label: "Ordentliche Sitzung",
  },
  {
    id: 2,
    label: "Außerordentliche Sitzung",
  },
];
const sitzungFormatOptions = [
  {
    id: 0,
    label: "",
  },
  {
    id: 1,
    label: "Präsenz",
  },
  {
    id: 2,
    label: "Online",
  },
  {
    id: 2,
    label: "Hybrid",
  },
];

const defaultTableColumns: TableColumnType[] = [
  {
    id: "sitzungDate",
    label: "Datum der Sitzung",
    filterPlaceholder: "Filtern nach Datum",
    stateFilter: "dateFilter",
  },
  {
    id: "sitzungName",
    label: "Name der Sitzung/des Kanals in Teams",
    filterPlaceholder: "Filtern nach Name",
    stateFilter: "nameFilter",
  },
  {
    id: "sitzungArt",
    label: "Art der Sitzung",
    filterPlaceholder: "Filtern nach Sitzungsart",
    stateFilter: "artFilter",
  },
  {
    id: "sitzungFormat",
    label: "Format der Sitzung",
    filterPlaceholder: "Filtern nach Format",
    stateFilter: "formatFilter",
  },
];

export const SortedTable = ({ filteredData, state, handleInputChange, resetDropdowns }) => {
  let items: SitzungItem[] = [];
  filteredData.map((row) =>
    items.push({ item: row.item, onClick: row.onClick })
  );
  const [selectedSitzungFormat, setSelectedSitzungFormat] = useState("");
  const [selectedSitzungArt, setSelectedSitzungArt] = useState("");

  useEffect(() => {
    handleInputChange(selectedSitzungFormat, "formatFilter");
  }, [selectedSitzungFormat, handleInputChange]);

  useEffect(() => {
    handleInputChange(selectedSitzungArt, "artFilter");
  }, [selectedSitzungArt, handleInputChange]);

  useEffect(() => {
    setSelectedSitzungArt('')
    setSelectedSitzungFormat('')
  }, [resetDropdowns]);

  const columns: TableColumnDefinition<SitzungItem>[] = [
    createTableColumn<SitzungItem>({
      columnId: "sitzungDate",
      compare: (a, b) => {
        return a.item.sitzungDate.localeCompare(b.item.sitzungDate);
      },
    }),
    createTableColumn<SitzungItem>({
      columnId: "sitzungName",
      compare: (a, b) => {
        return a.item.sitzungName.localeCompare(b.item.sitzungName);
      },
    }),
    createTableColumn<SitzungItem>({
      columnId: "sitzungArt",
      compare: (a, b) => {
        return a.item.sitzungArt.localeCompare(b.item.sitzungArt);
      },
    }),
    createTableColumn<SitzungItem>({
      columnId: "sitzungFormat",
      compare: (a, b) => {
        return a.item.sitzungFormat.localeCompare(b.item.sitzungFormat);
      },
    }),
  ];

  const {
    getRows,
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSort({
        defaultSortState: {
          sortColumn: "sitzungDate",
          sortDirection: "descending",
        },
      }),
    ]
  );

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  const rows = sort(getRows());

  return (
    <Table sortable aria-label="Table with sort">
      <TableHeader>
        <TableRow key={'columns'}>
          {defaultTableColumns.map((column, i) => (
            <TableHeaderCell
              key={i}
              style={{
                fontWeight: "bold",
                // width: column.id === "sitzungName" ? "350px" : "235px",
              }}
              {...headerSortProps(column.id)}
            >
              {column.label}
            </TableHeaderCell>
          ))}
        </TableRow>
        <TableRow key={'filters'}>
          {defaultTableColumns.map((column, i) => (
            <TableHeaderCell key={i} style={{ padding: "6px 0", width: 50 }}>
              {(column.id === "sitzungDate" || column.id === "sitzungName") && (
                <Input
                  className="filter-input-style"
                  contentBefore={
                    <FilterIcon />
                  }
                  onChange={(e) =>
                    handleInputChange(e.target.value, column.stateFilter)
                  }
                  value={state[column.stateFilter] || ""}
                  placeholder={column.filterPlaceholder}
                ></Input>
              )}
              {column.id === "sitzungArt" && (
                <Dropdown
                  className="filter-dropdown"
                  onOptionSelect={(e, v) => {
                    setSelectedSitzungArt(v.optionValue!);
                  }}
                  value={selectedSitzungArt}
                  placeholder="Filtern nach Art"
                >
                  {artDerSitzungOptions.map((o, i) => (
                    <Option key={i} value={o.label}>{o.label}</Option>
                  ))}
                </Dropdown>
              )}
              {column.id === "sitzungFormat" && (
                <Dropdown
                  className="filter-dropdown"
                  onOptionSelect={(e, v) => {
                    setSelectedSitzungFormat(v.optionValue!);
                  }}
                  value={selectedSitzungFormat}
                  placeholder="Filtern nach Format"
                >
                  {sitzungFormatOptions.map((o, i) => (
                    <Option key={i} value={o.label}>{o.label}</Option>
                  ))}
                </Dropdown>
              )}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {rows.map((row, i) => (
          <TableRow
            style={{ cursor: "pointer" }}
            onClick={row.item.onClick}
            key={i}
          >
            <TableCell>
              <TableCellLayout>
                {moment(row.item.item.sitzungDate).format("DD.MM.YYYY")}
              </TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout>{row.item.item.sitzungName}</TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout>{row.item.item.sitzungArt}</TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout>{row.item.item.sitzungFormat}</TableCellLayout>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
