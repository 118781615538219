/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, DialogSurface, DialogTitle, Spinner } from "@fluentui/react-components";
import { DismissFilled } from '@fluentui/react-icons';
import { KonfigurationGremiumForm } from "../konfiguration/konfiguration-gremium-form/konfiguration-gremium-form";
import { createRef, useEffect, useState } from "react";
import { getAllUsersAsync, getUserProfilesAsync } from "../../api/users";
import { StatusCodes } from "http-status-codes";
import { getJoinedTeams } from "../../api/teams-api";
import IGremium from "../../models/konfiguration/gremium";
import { app } from "@microsoft/teams-js";
import { LoaderMessage } from "../../constants/textLabels";
import { createNewAuschuss } from "../../api/gremium-details-api";
import ITeamsTeam from "../../models/teams/teams-team";
import IGraphUser from "../../models/graph-user";
import { Guid } from "guid-typescript";
import { getAllGremiumKonfigs, getGremiumKonfig, postGremiumAdministrator } from "../../api/konfiguration-api";
import IAdministrator from "../../models/konfiguration/administrator";
import IGremiumCreate from "../../models/konfiguration/gremiumCreate";
import { getAllMitgliedersFromParent } from "../../api/konfig-mitglieder-api";

const defaultGremiumObject: IGremiumCreate = {
  id: Guid.createEmpty().toString(),
  gremienname: "",
  anzahlMitglieder: 0,
  anzahlErsatzmitglieder: 0,
  anzahlJAV: 0,
  anzahlSBV: 0,
  assistenzUserId: Guid.createEmpty().toString(),
  hatAssistenzVollzugriff: false,
  defaultTeamId: Guid.parse(Guid.EMPTY),
  showDauerOnTagesordnung: false,
  showHasAttachmentsOnTagesordnung: false,
  showResponsiblePersonOnTagesordnung: false,
  protokollname: '',
  tagesordnungsname: '',
  parentGremiumId: Guid.createEmpty(),
  ortFiliale: '',
  firmenname: '',
  brMail: '',
  anwesenheitslistenname: '',
  administratorUsers: []
};

export const GremiumDialog = ({
  setGremiumDialog,
  showGremiumDialog,
  userPermissions,
  auschuss,
}) => {
  const [adminDeleteDialogOpen, setAdminDeleteDialogOpen] = useState(false);
  const [administratorUserNames, setAdministratorUserNames] = useState<
    string[]
  >([]);
  const [assistantUserNames, setAssistantUserNames] = useState<
    string[]
  >([]);
  const [updatedAdministratorUserNames, setUpdatedAdministratorUserNames] =
    useState<string[]>([]);
  const [updatedAssistentUserNames, setUpdatedAssistentUserNames] =
    useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [teams, setTeams] = useState<ITeamsTeam[]>([]);
  const [graphUsers, setGraphUsers] = useState<IGraphUser[]>([]);
  const [initialGremium, setInitialGremium] =
    useState<IGremium>(defaultGremiumObject);
  const [updatedGremium, setUpdatedGremium] =
    useState<IGremium>(defaultGremiumObject);
  const [currentLoggedUsername, setCurrentLoggedUsername] = useState("");
  const [teamDisplayName, setTeamDisplayName] = useState("");
  // const [assistentUserName, setAssistentUserName] = useState("");
  const [configDataChanged, setConfigDataChanged] = useState(false);
  const [memberCountDecreaseRejected, setMemberCountDecreaseRejected] =
    useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [adminDropdownRef, setAdminDropdownRef] = useState<any>(createRef());
  const [teamsContext, setTeamsContext] = useState<app.Context | null>(null);
  const [spreichenClicked, setSpreichenClicked] = useState<boolean>(false);
  const [isCreateNew, setIsCreateNew] = useState<boolean>(false);
  const [deleteAdmin, setDeleteAdmin] = useState<boolean>(false);
  const [auschussAdmins, setAuschussAdmins] = useState([]);
  const [allGremiums, setAllGremiums] = useState<IGremium[]>([]);
  const [nameExists, setNameExists] = useState<boolean>(false)


  const getGremiumData = async () => {
    const { data } = await getGremiumKonfig(auschuss.gremiumId, handleTokenAccessFailure);
    if (data) {
      setInitialGremium((prevState) => {
        return {
          ...prevState,
          firmenname: data.firmenname,
          ortFiliale: data.ortFiliale,
          parentGremiumId: auschuss.gremiumId
        };
      });
    }
  };

  const getAllGremiums = async () => {
    const response = await getAllGremiumKonfigs(handleTokenAccessFailure)
    setAllGremiums(response.data)
  }

  useEffect(() => {
    getGremiumData();
    fetchContext();
    getAllGremiums()
  }, []);

  useEffect(() => {
    if (teamsContext) {
      loadTeams();
    }
  }, [teamsContext]);

  useEffect(() => {
    updateAdministratorUserNames();
  }, [updatedAdministratorUserNames]);

  useEffect(() => {
    updateAssistentUserNames();
  }, [updatedAssistentUserNames]);

  useEffect(() => {
    if (initialGremium) {
      setCurrentLoggedUsername(currentLoggedUsername);
      populateTeamDisplayName();
      setUpdatedGremium(initialGremium);
      setIsLoading(false);
    }
  }, [initialGremium]);

  useEffect(() => {
    if (graphUsers.length > 0) {
      loadGremiumAsync();
    }
  }, [graphUsers]);

  const fetchContext = async () => {
    setIsLoading(true);
    app.getContext().then((context: app.Context) => {
      setTeamsContext(context);
    });
  };

  const handleTokenAccessFailure = (error: string) => {
    alert(error);
  };

  const loadTeams = async () => {
    const response = await getJoinedTeams(handleTokenAccessFailure);
    if (response.status === StatusCodes.OK && response.data) {
      setTeams(response.data);
      await loadAllUsersAsync();
    }
  };

  const loadAllUsersAsync = async () => {
    let response = await getAllUsersAsync(handleTokenAccessFailure);
    if (response.status === StatusCodes.OK && response.data) {
      const resp = await getAllMitgliedersFromParent(auschuss.gremiumId, handleTokenAccessFailure)
      let userIds: string[] = []
      resp.data.forEach((u) => {
        userIds.push(u.graphUserId)
      })
      const gremiumMitglieders = await getUserProfilesAsync(userIds, handleTokenAccessFailure)
      setGraphUsers(gremiumMitglieders.data)
    }
  };

  const loadGremiumAsync = async () => {
    const currentLoggedUserId = teamsContext?.user?.id;
    const currentLoggedUsername = graphUsers.find(
      (u) => u.id.toString() === currentLoggedUserId
    )?.displayName;
    setCurrentLoggedUsername(currentLoggedUsername || "");
  };

  const populateTeamDisplayName = () => {
    const teamId = initialGremium.defaultTeamId;
    const teamDisplayName = teams.find((t) => t.id === teamId)?.displayName;
    setTeamDisplayName(teamDisplayName || "");
  };

  const configDataChange = (e) => {
    if (
      e &&
      e.target ===
      adminDropdownRef.current.querySelector(
        ".ui-dropdown__searchinput__input"
      )
    ) {
      return;
    }
    setConfigDataChanged(true);
    sessionStorage.setItem("configDataChanged", "true");
  };

  const isFormValid = () => {
    return (
      !nameExists &&
      initialGremium.gremienname.trim() !== "" &&
      initialGremium.firmenname.trim() !== "" &&
      initialGremium.brMail.trim() !== "" &&
      initialGremium.defaultTeamId!.toString() != Guid.createEmpty().toString() &&
      administratorUserNames.length > 0
    );
  };

  const confirmDecreasingMemberCount = () => {
    const response = window.confirm(
      "Die Verkleinerung der Gremiumsgröße kann zur automatischen Entfernung von Gremiumsmitgliedern führen!"
    );
    if (response === false) {
      setMemberCountDecreaseRejected(true);
    }
    return response;
  };

  const onGremiennameChange = (event: any) => {
    let gremienname = event.target.value;
    const checkGremiumName = allGremiums.find((g) => g.gremienname === gremienname)
    if (checkGremiumName) {
      setNameExists(true)
    } else {
      setNameExists(false)
    }
    setInitialGremium((prevState) => ({ ...prevState, gremienname }));
    setUpdatedGremium({ ...initialGremium, gremienname });
  };

  const onFirmenameChange = (event: any) => {
    let firmenname = event.target.value;
    setInitialGremium((prevState: any) => ({ ...prevState, firmenname }));
    setUpdatedGremium({ ...initialGremium, firmenname });
  };

  const onOrtFilialeChange = (event: any) => {
    let ortFiliale = event.target.value;
    setInitialGremium((prevState: any) => ({ ...prevState, ortFiliale }));
    setUpdatedGremium({ ...initialGremium, ortFiliale });
  };

  const onMitgliederChange = (event: any) => {
    let anzahlMitglieder: number = parseInt(event.target.value);
    let prevValue = initialGremium.anzahlMitglieder;

    if (
      anzahlMitglieder === null ||
      anzahlMitglieder < 1 ||
      Number.isNaN(anzahlMitglieder)
    ) {
      setInitialGremium((prevState: any) => ({ ...prevState, prevValue }));
      setUpdatedGremium({ ...initialGremium, anzahlMitglieder });
      return;
    }

    if (prevValue > anzahlMitglieder) {
      if (confirmDecreasingMemberCount() === false) return;
    }

    setInitialGremium((prevState: any) => ({ ...prevState, anzahlMitglieder }));
    setUpdatedGremium({ ...initialGremium, anzahlMitglieder });
  };

  const onErsatzmitgliederChange = (event: any) => {
    let anzahlErsatzmitglieder: number = parseInt(event.target.value);
    let prevValue = initialGremium.anzahlErsatzmitglieder;

    if (
      anzahlErsatzmitglieder === null ||
      anzahlErsatzmitglieder < 0 ||
      Number.isNaN(anzahlErsatzmitglieder)
    ) {
      setInitialGremium((prevState: any) => ({ ...prevState, prevValue }));
      setUpdatedGremium({ ...initialGremium, anzahlErsatzmitglieder });
      return;
    }

    if (prevValue > anzahlErsatzmitglieder) {
      if (confirmDecreasingMemberCount() === false) return;
    }

    setInitialGremium((prevState: any) => ({
      ...prevState,
      anzahlErsatzmitglieder,
    }));
    setUpdatedGremium({ ...initialGremium, anzahlErsatzmitglieder });
  };

  const onJAVChange = (event: any) => {
    let anzahlJAV: number = parseInt(event.target.value);
    let prevValue = initialGremium.anzahlJAV;

    if (anzahlJAV === null || anzahlJAV < 0 || Number.isNaN(anzahlJAV)) {
      setInitialGremium((prevState: any) => ({ ...prevState, prevValue }));
      setUpdatedGremium({ ...initialGremium, anzahlJAV });
      return;
    }

    if (prevValue > anzahlJAV) {
      if (confirmDecreasingMemberCount() === false) return;
    }

    setInitialGremium((prevState: any) => ({ ...prevState, anzahlJAV }));
    setUpdatedGremium({ ...initialGremium, anzahlJAV });
  };

  const onSBVChange = (event: any) => {
    let anzahlSBV: number = parseInt(event.target.value);
    let prevValue = initialGremium.anzahlSBV;

    if (anzahlSBV === null || anzahlSBV < 0 || Number.isNaN(anzahlSBV)) {
      setInitialGremium((prevState: any) => ({ ...prevState, prevValue }));
      setUpdatedGremium({ ...initialGremium, anzahlSBV });
      return;
    }

    if (prevValue > anzahlSBV) {
      if (confirmDecreasingMemberCount() === false) return;
    }

    setInitialGremium((prevState: any) => ({ ...prevState, anzahlSBV }));
    setUpdatedGremium({ ...initialGremium, anzahlSBV });
  };

  const onGremiumMailChange = (event: any) => {
    let brMail = event.target.value;
    setInitialGremium((prevState: any) => ({ ...prevState, brMail }));
    setUpdatedGremium({ ...initialGremium, brMail });
  };

  const onTeamChange = (_, v) => {
    const teamDisplayName = v.optionValue;
    const defaultTeamId = teamDisplayName
      ? teams.find((t) => t.displayName === teamDisplayName)?.id
      : undefined;

    setInitialGremium((prevState: any) => ({ ...prevState, defaultTeamId }));
    setUpdatedGremium({ ...initialGremium, defaultTeamId });
    setTeamDisplayName(teamDisplayName);

    configDataChange(null);
  };

  const updateAdministratorUserNames = () => {
    const administratorUserNames = updatedAdministratorUserNames;
    const administratorUsers = administratorUserNames.map(
      (name) => graphUsers.find((user) => name === user.displayName)!.id
    );

    if (administratorUsers) {
      setAdministratorUserNames(administratorUserNames);
      setInitialGremium((prevState: any) => ({
        ...prevState,
        administratorUsers,
      }));
      // setUpdatedGremium({ ...initialGremium, administratorUsers });
    }

    configDataChange(null);
  };

  const updateAssistentUserNames = () => {
    const assistentUserNames = updatedAssistentUserNames;
    const assistanceUsers = assistentUserNames.map(
      (name) => graphUsers.find((user) => name === user.displayName)!.id
    );

    if (assistanceUsers) {
      setAssistantUserNames(assistentUserNames);
      setInitialGremium((prevState: any) => ({
        ...prevState,
        assistanceUsers,
      }));
      // setUpdatedGremium({ ...initialGremium, administratorUsers });
    }

    configDataChange(null);
  };

  const onGremiumAdministratorChange = async (_, v) => {
    if (!userPermissions) {
      return;
    }
    setAuschussAdmins(v.selectedOptions)
    setUpdatedAdministratorUserNames(v.selectedOptions);
  };

  const onGremiumAssistentChange = async (_, v) => {
    if (!userPermissions) {
      return;
    }
    setAssistantUserNames(v.selectedOptions);
    setUpdatedAssistentUserNames(v.selectedOptions);
  };

  const createNewGremium = async () => {
    setIsCreateNew(true);
    setSpreichenClicked(true);

    if (isFormValid() === false) {
      return;
    }
    const resp = await createNewAuschuss(
      updatedGremium,
      handleTokenAccessFailure
    );

    if (resp.data && resp.status === StatusCodes.OK) {
      const newGremium = resp.data;
      localStorage.setItem("selectedAuschuss", newGremium.id);
      auschuss.setGremiumId(newGremium.id);
    }
    setGremiumDialog(false);
  };

  return (
    <Dialog
      open={showGremiumDialog}
    ><DialogSurface style={{ width: "60%", height: "fit-content", overflowY: "hidden" }}>
        <DialogTitle style={{ display: "flex" }}>
          <span style={{ width: "100%", textAlign: "center", fontWeight: "bold", fontSize: "28px" }}>
            Neuer Ausschuss
          </span>
          <DismissFilled className="dialog-close-icon" onClick={() => setGremiumDialog(false)} y2=""></DismissFilled>
        </DialogTitle>
        {isLoading ? (
          <Spinner
            style={{
              width: "100%",
              height: "100vh",
              position: "fixed",
              top: "0",
              left: "0",
            }}
            className="loader"
            label={LoaderMessage}
          />
        ) : (
          <KonfigurationGremiumForm
            adminDeleteDialogOpen={adminDeleteDialogOpen}
            adminDropdownRef={adminDropdownRef}
            setAdminDeleteDialogOpen={setAdminDeleteDialogOpen}
            setDeleteAdmin={setDeleteAdmin}
            administratorUserNames={administratorUserNames}
            assistentUserName={assistantUserNames}
            configDataChange={configDataChange}
            configDataChanged={configDataChanged}
            currentLoggedUsername={currentLoggedUsername}
            adminUsers={graphUsers}
            assistanceUsers={graphUsers}
            initialGremium={initialGremium}
            isLoading={isLoading}
            teams={teams}
            userHasUpdatePermission={userPermissions}
            teamDisplayName={teamDisplayName}
            isKonfigurationGremium={spreichenClicked}
            onDataUpdate={() => { }}
            onGremiumAssistentChange={onGremiumAssistentChange}
            onErsatzmitgliederChange={onErsatzmitgliederChange}
            onGremiennameChange={onGremiennameChange}
            onGremiumMailChange={onGremiumMailChange}
            onJAVChange={onJAVChange}
            onMitgliederChange={onMitgliederChange}
            onOrtFilialeChange={onOrtFilialeChange}
            onSBVChange={onSBVChange}
            onTeamChange={onTeamChange}
            onFirmenameChange={onFirmenameChange}
            dialogSaveFunction={createNewGremium}
            dialogAbortFunction={() => setGremiumDialog(false)}
            updateAdministratorUserNames={updateAdministratorUserNames}
            updateAssistentUserNames={updateAssistentUserNames}
            onGremiumAdministratorChange={onGremiumAdministratorChange}
            updatedAdministratorUserNames={updatedAdministratorUserNames}
            updatedAssitentUserNames={updatedAssistentUserNames}
            isFormValid={isFormValid}
            isCreateNewAuschuss={isCreateNew}
            gremiumNameExists={nameExists}
          />
        )}

      </DialogSurface></Dialog>
  );
};
