import axios from "./axios-decorator";
import NewTagesordnungspunkt from "../models/tagesordnung/tagesordnungspunkt-subitem";
import ITagesordnungspunkt from "../models/tagesordnung/tagesordnungspunkt";
import { AxiosHeaders } from "axios";

export const getTagesordnungDto = async (
  handleTokenAccessFailure: (error: string) => void
) => {
  let url = `api/TagesordnungpunktDto`;
  return await axios.get(url, handleTokenAccessFailure);
};
export const getTagesordnungSitzungDto = async (
  id: string,
  handleTokenAccessFailure: (error: string) => void
) => {
  let url = `/api/TagesordnungpunktDto/sitzung/${id}`;
  return await axios.get(url, handleTokenAccessFailure);
};

export const updateTagesordnungDto = async (
  data: ITagesordnungspunkt,
  handleTokenAccessFailure: (error: string) => void
) => {
  let url = `api/TagesordnungpunktDto/${data.id}`;
  return await axios.put(url, handleTokenAccessFailure, data);
};

export const patchTagesordnungDtoPunkt = async (
  propValue,
  propName: string,
  id: string,
  handleTokenAccessFailure: (error: string) => void
) => {
  const data = [{
    "op": "replace",
    "path": "/" + propName,
    "value": propValue,
    "from": ""
  }];
  const config = {
    headers: new AxiosHeaders({
      'Content-Type': 'application/json-patch+json',
    })
  }
  let url = `/api/TagesordnungpunktDto/${id}`;
  return await axios.patch(url, handleTokenAccessFailure, data, config);
};

export const deleteRowTagesordnung = async (
  id: string,
  handleTokenAccessFailure: (error: string) => void
) => {
  let url = `/api/TagesordnungpunktDto/${id}`;
  return await axios.delete(url, handleTokenAccessFailure);
};

export const insertTagesordnungpunktDto = async (
  item: NewTagesordnungspunkt,
  handleTokenAccessFailure: (error: string) => void
) => {
  let url = "/api/TagesordnungpunktDto";
  return await axios.post(url, handleTokenAccessFailure, item);
};
