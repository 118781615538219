import {
  Dropdown,
  OptionGroup,
  Option,
  Button,
} from "@fluentui/react-components";
import { AuschussContext } from "../../providers/auschuss-provider";
import {
  PeopleCommunityAdd28Filled,
  Delete28Filled,
} from "@fluentui/react-icons";

const getGroupedGremiums = (gremiumList) => {
  let grouped = gremiumList.map((gremium) => {
    const groupedGremiums = gremiumList.filter(
      (g) => g.parentGremiumId === gremium.id
    );
    if (groupedGremiums.length > 0) {
      return { name: gremium.name, subGremiums: groupedGremiums };
    }
  });
  grouped = grouped.filter((g) => g);
  return grouped;
};

export const GremiumDropdown = ({
  userPermissions,
  setShowGremiumDialog,
  setShowGremiumDeleteDialog,
  setPermissionsLoaded,
}) => {
  return (
    <AuschussContext.Consumer>
      {(auschuss: any) => {
        let admin;
        const gremium = auschuss.gremiumList.find((g) => g.parentGremiumId === null).id;
        const storedAuschuss = localStorage.getItem("selectedAuschuss");

        let selected;

        if (storedAuschuss) {
          selected = auschuss.gremiumList.find((g) => g.id === storedAuschuss);
        } else {
          selected = auschuss.gremiumList.find(
            (g) => g.id === auschuss.gremiumId
          );
        }
        if (!selected) {
          selected = auschuss.gremiumList.find(
            (g) => g.parentGremiumId === null
          );
        }
        if (userPermissions) {
          admin = userPermissions.find((p) => p.name === "GremiumCreate");
        }
        const dropDownOptions = getGroupedGremiums(auschuss.gremiumList);
        return (
          <div className="auschuss-dropdown-container">
            <Dropdown
              value={selected.name}
              style={{ borderBottomColor: "#d1d1d1" }}
              selectedOptions={[selected]}
              onOptionSelect={(e, data: any) => {
                auschuss.setGremiumId(data.optionValue.id);
                localStorage.setItem("selectedAuschuss", data.optionValue.id);
                setPermissionsLoaded(false);
              }}
            >
              <OptionGroup label="Gremien">
                {auschuss.gremiumList
                  .filter((g) => g.parentGremiumId === null)
                  .map((option) => (
                    <Option value={option} key={option.id}>
                      {option.name}
                    </Option>
                  ))}
              </OptionGroup>
              {dropDownOptions.map((group) => (
                <OptionGroup
                  key={group.name}
                  label={`${group.name} - Auschüsse`}
                >
                  {group.subGremiums.map((option) => (
                    <Option value={option} key={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </OptionGroup>
              ))}
            </Dropdown>
            {userPermissions && admin.value && selected.id === gremium && (
              <Button
                iconPosition="before"
                icon={<PeopleCommunityAdd28Filled />}
                onClick={() => {
                  setShowGremiumDialog(true);
                }}
                appearance="primary"
                size="medium"
                style={{ fontWeight: "normal" }}
              >
                Hinzufügen
              </Button>
            )}
            {userPermissions &&
              admin.value &&
              auschuss.gremium?.parentGremiumId && (
                <Button
                  iconPosition="before"
                  icon={<Delete28Filled />}
                  onClick={() => {
                    setShowGremiumDeleteDialog(true);
                  }}
                  className="deleteButton"
                  appearance="primary"
                  size="medium"
                >
                  Löschen
                </Button>
              )}
          </div>
        );
      }}
    </AuschussContext.Consumer>
  );
};
