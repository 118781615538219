import axios from "./axios-decorator";
import ITagesordnungspunkt from "../models/tagesordnung/tagesordnungspunkt";
import NewTagesordnungspunkt from "../models/tagesordnung/tagesordnungspunkt-subitem";
import { AxiosHeaders } from "axios";

const generateId = (data) =>
  data.reduce((acc, current) => Math.max(acc, current.ProductID), 0) + 1;

export const getGremiumDetailDto = async (
  id: string,
  handleTokenAccessFailure: (error: string) => void
) => {
  let url = `/api/GremiumDetailsDto/${id}`;
  return await axios.get(url, handleTokenAccessFailure);
};

export const updateKonfigTagesordnung = async (
  data: ITagesordnungspunkt,
  handleTokenAccessFailure: (error: string) => void
) => {
  let url = `api/TagesordnungpunktVorlageDto/${data.id}`;
  return await axios.put(url, handleTokenAccessFailure, data);
};

export const patchKonfigTagesordnung = async (
  propValue,
  propName: string,
  id: string,
  handleTokenAccessFailure: (error: string) => void
) => {
  const data = [{
    "op": "replace",
    "path": "/" + propName,
    "value": propValue,
    "from": ""
  }];
  const config = {
    headers: new AxiosHeaders({
      'Content-Type': 'application/json-patch+json',
    })
  }
  let url = `api/TagesordnungpunktVorlageDto/${id}`;
  return await axios.patch(url, handleTokenAccessFailure, data, config);
};

export const deleteRowKonfigTagesordnung = async (
  id: string,
  handleTokenAccessFailure: (error: string) => void
) => {
  let url = `/api/TagesordnungpunktVorlageDto/${id}`;
  return await axios.delete(url, handleTokenAccessFailure);
};

export const insertTagesordnungpunktVorlageDto = async (
  item: NewTagesordnungspunkt,
  handleTokenAccessFailure: (error: string) => void
) => {
  let url = "/api/TagesordnungpunktVorlageDto";
  return await axios.post(url, handleTokenAccessFailure, item);
};
