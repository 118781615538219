import axios from "./axios-decorator";
import ISitzung from "../models/sitzung/create-sitzung";
import { MeetingPhase } from "../enums/meeting-phase.enum";
import { AxiosHeaders } from "axios";

export const getMeeting = async (meetingId: string, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/SitzungDto/' + meetingId;
    return await axios.get(url, handleTokenAccessFailure);
}

export const getAllMeetings = async (handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/SitzungDto';
    return await axios.get(url, handleTokenAccessFailure);
}

export const postMeeting = async (newMeeting: ISitzung, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/SitzungDto';
    newMeeting.id = newMeeting.id?.toString();
    newMeeting.room = newMeeting.room.toString();
    return await axios.post(url, handleTokenAccessFailure, newMeeting);
}

export const putMeeting = async (meetingId: string, newMeeting: ISitzung, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/SitzungDto/' + meetingId;
    return await axios.put(url, handleTokenAccessFailure, newMeeting);
}

export const patchMeeting = async (meetingId: string, propName: string, propValue, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/SitzungDto/' + meetingId;
    const data = [{
        "op": "replace",
        "path": "/" + propName,
        "value": propValue,
        "from": ""

    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    }
    return await axios.patch(url, handleTokenAccessFailure, data, config);
}

export const finishMeetingPhase = async (meetingId: string, meetingPhase: MeetingPhase, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/SitzungDto/' + meetingId + '/complete/' + meetingPhase;
    return await axios.post(url, handleTokenAccessFailure);
}
