import { Button, Dialog, DialogBody, DialogSurface, DialogTitle, DialogContent, Dropdown, Option, Combobox, Tooltip, Input, Field } from "@fluentui/react-components";
import { DismissFilled } from '@fluentui/react-icons';
import { AuschussSameNameErrorMessage, RequiredFieldErrorMessage } from "../../../constants/textLabels";
import { WarningDialog } from "../../common/WarningDialog/WarningDialog";
import { InfoIcon } from "../../../utils/icons";
import { useState } from "react";
import IGraphUser from "../../../models/graph-user";
import { customComboboxSearch } from "../../../utils/combobox-search";
import './konfiguration-form.scss'

export const KonfigurationGremiumForm = ({
  configDataChange,
  adminDeleteDialogOpen,
  setAdminDeleteDialogOpen,
  currentLoggedUsername,
  initialGremium,
  onGremiennameChange,
  userHasUpdatePermission,
  onFirmenameChange,
  onOrtFilialeChange,
  onMitgliederChange,
  onErsatzmitgliederChange,
  onJAVChange,
  onSBVChange,
  onGremiumMailChange,
  onGremiumAssistentChange,
  adminUsers,
  assistanceUsers,
  assistentUserName,
  administratorUserNames,
  adminDropdownRef,
  teams,
  teamDisplayName,
  onTeamChange,
  isLoading,
  configDataChanged,
  onDataUpdate,
  isKonfigurationGremium,
  dialogSaveFunction = () => { },
  dialogAbortFunction = () => { },
  updateAdministratorUserNames,
  onGremiumAdministratorChange,
  updatedAdministratorUserNames,
  isFormValid,
  isCreateNewAuschuss,
  navMenuItemClicked = () => { },
  setDeleteAdmin,
  gremiumNameExists,
  updatedAssitentUserNames,
  updateAssistentUserNames
}) => {
  const formRef = null;
  const [adminOptions, setAdminOptions] = useState<IGraphUser[]>(adminUsers)
  const [assistentsOptions, setAssistentsOptions] = useState<IGraphUser[]>(assistanceUsers)
  const [canChange, setCanChange] = useState(true)

  const customSearchAdmins = (e) => {
    const adminOptions = customComboboxSearch(adminUsers, e.target.value)
    setAdminOptions(adminOptions)
  }

  const customSearchAssistents = (e) => {
    const assistentsOptions = customComboboxSearch(assistanceUsers, e.target.value)
    setAssistentsOptions(assistentsOptions)
  }

  const resetOptionsAdmins = () => {
    setAdminOptions(adminUsers)
  }

  const resetOptionsAssistents = () => {
    setAssistentsOptions(assistanceUsers)
  }

  return (
    <div
      className="konfiguration__settings-form"
      style={{ justifyContent: "normal", padding: "5px" }}
      ref={formRef}
      onInput={configDataChange}
    >
      <Dialog
        open={adminDeleteDialogOpen}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle style={{ display: "flex", justifyContent: "end" }}>
              <DismissFilled className="dialog-close-icon" onClick={() => setAdminDeleteDialogOpen(false)} y2=""></DismissFilled>
            </DialogTitle>
            <DialogContent>
              <div>
                {updatedAdministratorUserNames?.length === 0 ||
                  !updatedAdministratorUserNames?.includes(currentLoggedUsername) ? (
                  <div className="Konfiguration-admin-delete-modal">
                    <div>
                      {updatedAdministratorUserNames?.includes(
                        currentLoggedUsername
                      )
                        ? "Dieses Feld kann nicht leer sein"
                        : "Sie können sich nicht selbst löschen."}
                    </div>
                    <Button
                      appearance="secondary"
                      onClick={() => {
                        setAdminDeleteDialogOpen(false);
                      }}
                    >
                      Schließen
                    </Button>
                  </div>
                ) : (
                  <WarningDialog
                    konfiguration
                    firstButtonFunction={() => {
                      setAdminDeleteDialogOpen(false);
                      setDeleteAdmin(true)
                      updateAdministratorUserNames();
                    }}
                    secondButtonFunction={() => {
                      setAdminDeleteDialogOpen(false);
                      setDeleteAdmin(false)
                    }}
                  />
                )}
              </div>
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <h3 className="Konfiguration-formSectionTitle">Gremium</h3>
      <Field
        className="field-style"
        validationMessage={isKonfigurationGremium && initialGremium.gremienname === ""
          ? RequiredFieldErrorMessage
          : gremiumNameExists ? AuschussSameNameErrorMessage : ""}
        validationState={isKonfigurationGremium && initialGremium.gremienname === "" ? "error" : gremiumNameExists ? "error" : "none"}
        label="Name">
        <Input
          value={initialGremium.gremienname}
          onChange={onGremiennameChange}
          disabled={!userHasUpdatePermission}
        />
      </Field>
      <div className="Konfiguration-formRow">
        <Field
          className="field-style"
          validationMessage={isKonfigurationGremium && initialGremium.firmenname === ""
            ? RequiredFieldErrorMessage
            : ""}
          validationState={isKonfigurationGremium && initialGremium.firmenname === "" && "error"}
          label="Betrieb">
          <Input
            value={initialGremium.firmenname}
            onChange={onFirmenameChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
        <Field
          className="field-style"
          label="Ort/Niederlassung">
          <Input
            value={initialGremium.ortFiliale}
            onChange={onOrtFilialeChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
      </div>
      <div className="Konfiguration-formRow">
        <Field
          className="field-style"
          label={<>Mitglieder{" "}
            <Tooltip
              relationship="label"
              content="Anzahl der ordentlichen BR-Mitglieder bzw. Ausschussmitglieder (wenn Ausschüsse vorhanden)"
            ><span><InfoIcon /></span></Tooltip></>}>
          <Input
            type="number"
            value={initialGremium.anzahlMitglieder}
            onChange={onMitgliederChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
        <Field
          className="field-style"
          label={<>Ersatzmitglieder{" "}
            <Tooltip
              relationship="label"
              content="Anzahl aller Ersatzmitglieder (bei Verhältniswahl die aller Listen), wenn vorhanden"
            ><span><InfoIcon /></span></Tooltip></>}>
          <Input
            type="number"
            value={initialGremium.anzahlErsatzmitglieder.toString()}
            onChange={onErsatzmitgliederChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
      </div>
      <div className="Konfiguration-formRow">
        <Field
          className="field-style"
          label={<>Jugend und Auszubildendenvertretung{" "}
            <Tooltip
              relationship="label"
              content="Größe der JAV (ohne Ersatzmitglieder), wenn vorhanden, bei Ausschüssen 0 auswählen"
            ><span><InfoIcon /></span></Tooltip></>}>
          <Input
            type="number"
            value={initialGremium.anzahlJAV.toString()}
            onChange={onJAVChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
        <Field
          className="field-style"
          label={<> Schwerbehindertenvertretung (SBV){" "}
            <Tooltip
              relationship="label"
              content="Vertrauensperson (SBV) plus sämtliche Stellvertreter, wenn vorhanden, bei Ausschüssen 0 auswählen"
            ><span><InfoIcon /></span></Tooltip></>}>
          <Input
            type="number"
            value={initialGremium.anzahlSBV.toString()}
            onChange={onSBVChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
      </div>
      <div className="Konfiguration-formRow">
        <Field
          className="field-style"
          validationMessage={isKonfigurationGremium && initialGremium.brMail === ""
            ? RequiredFieldErrorMessage
            : ""}
          validationState={isKonfigurationGremium && initialGremium.brMail === "" && "error"}
          label={<>E-Mail des Gremiums{" "}
            <Tooltip
              relationship="label"
              content="Standard-E-Mail-Adresse des Gremiums in Outlook, über die der Betriebsrat oder der Ausschuss für die Arbeitnehmer erreichbar ist"
            ><span><InfoIcon /></span></Tooltip></>}>
          <Input
            type="email"
            value={initialGremium.brMail}
            onChange={onGremiumMailChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
        <Field
          className="field-style"
          validationMessage={(teamDisplayName === null ||
            teamDisplayName === undefined ||
            teamDisplayName === "") &&
            isKonfigurationGremium &&
            "Standard Team darf nicht leer sein."}
          validationState={isKonfigurationGremium && (teamDisplayName === null ||
            teamDisplayName === undefined ||
            teamDisplayName === "") && "error"}
          label={<>Standard Team{" "}
            <Tooltip
              relationship="label"
              content="Achtung: Hier wählen Sie die von Ihnen bzw. Ihrer IT bei der Installation definierte Gruppe (Team) aus. In diesem Team werden die Kanäle für die Sitzungen mit dem Zugriff auf die dazugehörigen Dateien erstellt. Achten Sie also immer darauf, dass nur berechtigte Personen Team-Mitglieder sind."
            ><span><InfoIcon /></span></Tooltip></>}>
          <Dropdown
            onOptionSelect={onTeamChange}
            value={teamDisplayName}
            placeholder="Suche nach Team"
            disabled={!userHasUpdatePermission}
          >{teams.map((team) => <Option checkIcon={null} key={team.id} value={team.displayName}>{team.displayName}</Option>)}</Dropdown>
        </Field>
      </div>
      <div className="Konfiguration-formRow">
        <Field
          className="field-style"
          label={<>Assistenz{" "}
            <Tooltip
              relationship="label"
              content="Vor- und Nachname der Assistenz, wenn vorhanden, Achtung: Sie erhält die gleichen Rechte wie BRV und Stellvertreter"
            ><span><InfoIcon /></span></Tooltip>
          </>}>
          <Combobox
            multiselect={true}
            selectedOptions={assistentUserName}
            onOptionSelect={async (e, v) => {
              if(canChange){
                setCanChange(false)
                await onGremiumAssistentChange(e, v);
                resetOptionsAssistents()
                setCanChange(true)
              }
            }}
            freeform
            onChange={customSearchAssistents}
            className={!userHasUpdatePermission ? "disabledInput" : ""}
            disabled={!userHasUpdatePermission}
            placeholder="Suche nach Assistenz">
            {(assistentsOptions.length ? assistentsOptions : assistanceUsers).sort((a1, a2) => {
              if (a1.displayName.toLowerCase() < a2.displayName.toLowerCase()) { return -1; }
              if (a1.displayName.toLowerCase() > a2.displayName.toLowerCase()) { return 1; }
              return 0;
            }).map((user) => <Option key={user.id.toString()} value={!user.isActive ? `${user.displayName} (Inaktiv)`:user.displayName}>{`${user.displayName} ${(!user.isActive) ? "(Inaktiv)" : ''}`}</Option>)}
          </Combobox>
          <div style={{ marginTop: 5 }}>
            {assistentUserName.length ? (
              assistentUserName.map((option, i) => (
                <Button
                  size="small"
                  shape="circular"
                  appearance="secondary"
                  style={{ margin: 2 }}
                >
                  {option}
                </Button>
              ))
            ) : null}
          </div>
        </Field>
        <Field
          className="field-style"
          validationMessage={isKonfigurationGremium && !administratorUserNames.length && "In der Konfiguration ist keine weitere Person hinterlegt."}
          validationState={isKonfigurationGremium && !administratorUserNames.length && "error"}
          label={<>Gremium Administrator{" "}
            <Tooltip
              relationship="label"
              content="Vor- und Nachname des Administrators mit allen Schreibrechten, mindestens zwei empfohlen"
            ><span><InfoIcon /></span></Tooltip></>}>
          <Combobox
            multiselect={true}
            selectedOptions={administratorUserNames}
            onOptionSelect={async(e, v) => { 
              if(canChange){
                setCanChange(false)
                await onGremiumAdministratorChange(e, v); 
                resetOptionsAdmins()
                setCanChange(true)
              }
             }}
            ref={adminDropdownRef}
            freeform
            onChange={customSearchAdmins}
            className={!userHasUpdatePermission ? "disabledInput" : ""}
            disabled={!userHasUpdatePermission}
            placeholder="Suche nach Administrator">
            {(adminOptions.length ? adminOptions : adminUsers).sort((a1, a2) => {
              if (a1.displayName.toLowerCase() < a2.displayName.toLowerCase()) { return -1; }
              if (a1.displayName.toLowerCase() > a2.displayName.toLowerCase()) { return 1; }
              return 0;
            }).map((user) => <Option key={user.id.toString()} value={!user.isActive ? `${user.displayName} (Inaktiv)`:user.displayName}>{`${user.displayName} ${(!user.isActive) ? "(Inaktiv)" : ''}`}</Option>)}
          </Combobox>
          <div style={{ marginTop: 5 }}>
            {administratorUserNames.length ? (
              administratorUserNames.map((option, i) => (
                <Button
                  size="small"
                  shape="circular"
                  appearance="secondary"
                  style={{ margin: 2 }}
                >
                  {option}
                </Button>
              ))
            ) : null}
          </div>
        </Field>
      </div>
      {isKonfigurationGremium && !isCreateNewAuschuss ? (
        <div className="konfiguration__save-btn-wrapper" hidden={isLoading}>
          <Button appearance="secondary" disabled>Zurück</Button>
          <Button appearance="primary" onClick={() => navMenuItemClicked()}>Weiter</Button>
        </div>
      ) : (
        <div className="konfiguration__save-btn-wrapper" hidden={isLoading}>
          <Button appearance="primary" onClick={dialogSaveFunction} disabled={!configDataChanged}>Speichern</Button>
          <Button appearance="secondary" onClick={dialogAbortFunction}>Abbrechen</Button>
        </div>
      )}
    </div>
  );
};
