import { Button, Spinner, Input, Field, Dialog, DialogSurface, DialogTitle, Checkbox, MessageBar, Option, Tooltip } from "@fluentui/react-components";
import { Guid } from "guid-typescript";
import { StatusCodes } from "http-status-codes";
import React from 'react';
import { ConfirmCheckboxChange, LoaderMessage } from '../../constants/textLabels';
import { getAllMitglieders, patchMitglieder, postMitglieder } from "../../api/konfig-mitglieder-api";
import { getAllUsersAsync } from "../../api/users";
// import StatusBar from "../../components/common/toast-notification/toast-notification";
import { ActivityStatus } from "../../models/activity-status";
import IGraphUser from "../../models/konfiguration/graph-user-konfig";
import RadioGroup from "../common/radio-group/radio-group";
import { sortMitglieder } from "../../Helpers/mitgliederSortHelper";
import {
  IKonfigurationMitgliederProps,
  IKonfigurationMitgliederState,
  genderType,
} from "../../interfaces/konfiguration/konfiguration-mitglieder.interface";
import CustomCombobox from "../common/ComboBox/Combobox";
import { getGremiumKonfig } from "../../api/konfiguration-api";
import { Navigation } from "../../constants/constants";
import { Geschlecht } from "../../enums/geschlecht-enum";
import IMitgliederGremium from "../../models/konfiguration/gremium-mitglieder";
import ConfirmDialog from "../common/dialog/confirm-dialog";
import { CloseIcon, InfoIcon } from "../../utils/icons";

const gender = [
  { id: 1, type: "m /w /d" },
  { id: 2, type: "m" },
  { id: 3, type: "w" },
  { id: 4, type: "d" },
];



const gender_options = [
  {
    id: "m",
    displayName: "Männlich",
  },
  {
    id: "w",
    displayName: "Weiblich",
  },
  {
    id: "d",
    displayName: "Divers",
  },
];

class KonfigurationMitglieder extends React.Component<
  IKonfigurationMitgliederProps,
  IKonfigurationMitgliederState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      confirmDialogRef: React.createRef(),
      adminWarningDialogLoggedUser: false,
      typingTimer: undefined,
      optionsWorkerCouncil: [],
      optionsPickedInSBV: [],
      anzahlErsatzmitgliederKonfiguration: 0,
      anzahlJavKonfiguration: 0,
      anzahlMitgliederKonfiguration: 0,
      anzahlSbvKonfiguration: 0,
      isLoading: false,
      mitgliederObj: [{
        id: Guid.createEmpty(),
        graphUserId: Guid.createEmpty(),
        gremiumId: Guid.createEmpty(),
        geschlecht: Geschlecht["m /w /d"],
        userType: 0,
        vorsitzTyp: 0,
        kommentar: 'string',
        hatVollzugriff: false,
        sequenz: 0,
        autoInvite: false,
      }],
      check: 5,
      graphUsers: [],
      oMembers: [],
      secondMembers: [],
      thirdMembers: [],
      forthMembers: [],
      sampleUser: [],
      sampleGender: [],
      sampleComment: [],
      sampleHatVollzugriff: [],
      secondUser: [],
      secondGender: [],
      secondHatVollzugriff: [],
      secondComment: [],
      thirdUser: [],
      thirdGender: [],
      thirdHatVollzugriff: [],
      thirdComment: [],
      forthUser: [],
      forthGender: [],
      forthHatVollzugriff: [],
      forthComment: [],
      notification: { id: 0, message: "", type: ActivityStatus.None },
      formRef: React.createRef(),
      configDataChanged: false,
      adminWarningDialogOpen: false,
      shouldRemoveUserRights: false,
      adminChange: {
        i: null,
        type: null,
        value: null,
        user: null,
      },
    };

    sessionStorage.setItem("configDataChanged", "false");
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const resp = await getGremiumKonfig(localStorage.getItem("selectedAuschuss")!, this.handleTokenAccessFailure)
    this.setState({
      anzahlMitgliederKonfiguration: resp.data.anzahlMitglieder,
      anzahlErsatzmitgliederKonfiguration: resp.data.anzahlErsatzmitglieder,
      anzahlSbvKonfiguration: resp.data.anzahlSBV,
      anzahlJavKonfiguration: resp.data.anzahlJAV,
    })

    await this.loadAllUsersAsync();

  }

  loadMitglieder = async () => {
    let response = await getAllMitglieders(this.handleTokenAccessFailure);
    if (response.status === StatusCodes.OK && response.data) {
      const filterMitglieders: IMitgliederGremium[] = response.data.filter((data: IMitgliederGremium) => data.gremiumId.toString() === localStorage.getItem("selectedAuschuss")!)
      const mitgliederObj: IMitgliederGremium[] = sortMitglieder(filterMitglieders);
      this.setState({ mitgliederObj: mitgliederObj }, this.populateMembers);
    }

  };

  populateMembers = async () => {
    const emptyUser = {
      id: Guid.createEmpty(),
      displayName: '',
      isActive: false
    }
    //Data from API;
    let mitCountFromAPI = 0;
    let secondCountFromAPI = 0;
    let thirdCountFromAPI = 0;
    let forthCountFromAPI = 0;
    for (let i = 0; i < this.state.mitgliederObj.length; i++) {
      let m = this.state.mitgliederObj[i];
      if (m.userType === 1) {
        this.state.oMembers.push(m);
        let a: IGraphUser | undefined = this.state.graphUsers.find(
          (z) => z.id === m.graphUserId
        );
        if (a !== undefined) {
          this.state.sampleUser.push(a);
        } else {
          this.state.sampleUser.push(emptyUser)
        }
        let g = gender.find((z) => z.id === m.geschlecht);
        if (g !== undefined) {
          this.state.sampleGender.push(g);
        } else {
          this.state.sampleGender.push(gender[0])
        }
        this.state.sampleComment.push(m.kommentar);
        this.state.sampleHatVollzugriff.push(m.hatVollzugriff);
        mitCountFromAPI++;
      } else if (m.userType === 2) {
        this.state.secondMembers.push(m);
        let a: IGraphUser | undefined = this.state.graphUsers.find(
          (z) => z.id === m.graphUserId
        );
        if (a !== undefined) {
          this.state.secondUser.push(a);
        } else {
          this.state.secondUser.push(emptyUser);
        }
        let g = gender.find((z) => z.id === m.geschlecht);
        if (g !== undefined) {
          this.state.secondGender.push(g);
        } else {
          this.state.secondGender.push(gender[0]);
        }
        this.state.secondComment.push(m.kommentar);
        this.state.secondHatVollzugriff.push(m.hatVollzugriff);
        secondCountFromAPI++;
      } else if (m.userType === 3) {
        this.state.thirdMembers.push(m);
        let a: IGraphUser | undefined = this.state.graphUsers.find(
          (z) => z.id === m.graphUserId
        );
        if (a !== undefined) {
          this.state.thirdUser.push(a);
        } else {
          this.state.thirdUser.push(emptyUser)
        }
        let g = gender.find((z) => z.id === m.geschlecht);
        if (g !== undefined) {
          this.state.thirdGender.push(g);
        } else {
          this.state.thirdGender.push(gender[0])
        }
        this.state.thirdComment.push(m.kommentar);
        this.state.thirdHatVollzugriff.push(m.hatVollzugriff);
        thirdCountFromAPI++;
      } else if (m.userType === 4) {
        this.state.forthMembers.push(m);
        let a: IGraphUser | undefined = this.state.graphUsers.find(
          (z) => z.id === m.graphUserId
        );
        if (a !== undefined) {
          this.state.forthUser.push(a);
        } else {
          this.state.forthUser.push(emptyUser)
        }
        let g = gender.find((z) => z.id === m.geschlecht);
        if (g !== undefined) {
          this.state.forthGender.push(g);
        } else {
          this.state.forthGender.push(gender[0])
        }
        this.state.forthComment.push(m.kommentar);
        this.state.forthHatVollzugriff.push(m.hatVollzugriff);
        forthCountFromAPI++;
      }
    }

    //Data generated for Frontend
    let firstloopLength = this.state.anzahlMitgliederKonfiguration - this.state.oMembers.length;
    let secondloopLength = this.state.anzahlErsatzmitgliederKonfiguration - this.state.secondMembers.length;
    let thirdloopLength = this.state.anzahlJavKonfiguration - this.state.thirdMembers.length;
    let forthloopLength = this.state.anzahlSbvKonfiguration - this.state.forthMembers.length;
    let BRVpresent = this.state.oMembers.findIndex((z) => z.vorsitzTyp === 1);
    let STVpresent = this.state.oMembers.findIndex((z) => z.vorsitzTyp === 2);
    //looping through mitglieder
    let sequenz = 0;
    for (let i = 0; i < firstloopLength; i++) {
      if (BRVpresent === -1 && i === 0) {
        this.state.oMembers.push({
          id: Guid.createEmpty(),
          gremiumId: Guid.createEmpty(),
          geschlecht: 1,
          kommentar: "",
          userType: 1,
          vorsitzTyp: 1,
          graphUserId: null,
          hatVollzugriff: false,
          sequenz: sequenz,
          autoInvite: false,
        });
      } else if (STVpresent === -1 && i === 1) {
        this.state.oMembers.push({
          id: Guid.createEmpty(),
          gremiumId: Guid.createEmpty(),
          geschlecht: 1,
          kommentar: "",
          userType: 1,
          vorsitzTyp: 2,
          graphUserId: null,
          hatVollzugriff: false,
          sequenz: sequenz,
          autoInvite: false,
        });
      } else {
        this.state.oMembers.push({
          id: Guid.createEmpty(),
          gremiumId: Guid.createEmpty(),
          geschlecht: 1,
          kommentar: "",
          userType: 1,
          vorsitzTyp: 0,
          graphUserId: null,
          hatVollzugriff: false,
          sequenz: sequenz,
          autoInvite: false
        });
      }
      this.state.sampleUser.push({
        id: Guid.parse(Guid.EMPTY),
        displayName: "",
        isActive: false,
      });
      this.state.sampleGender.push({
        id: 1,
        type: "m /w /d",
      });

      this.state.sampleComment.push("");
      sequenz++;
    }

    //looping through anzahlErsatzmitglieder
    sequenz = 0;
    for (let i = 0; i < secondloopLength; i++) {
      this.state.secondMembers.push({
        id: Guid.createEmpty(),
        gremiumId: Guid.parse(localStorage.getItem("selectedAuschuss")!),
        geschlecht: 1,
        kommentar: "",
        userType: 2,
        vorsitzTyp: 0,
        graphUserId: null,
        hatVollzugriff: false,
        sequenz: sequenz,
        autoInvite: false,
      });
      this.state.secondUser.push({
        id: Guid.parse(Guid.EMPTY),
        displayName: "",
        isActive: false,
      });
      this.state.secondGender.push({
        id: 1,
        type: "m /w /d",
      });
      this.state.secondComment.push("");

      sequenz++;
    }

    //looping through JAV
    sequenz = 0;
    for (let i = 0; i < thirdloopLength; i++) {
      this.state.thirdMembers.push({
        id: Guid.createEmpty(),
        gremiumId: Guid.parse(localStorage.getItem("selectedAuschuss")!),
        geschlecht: 1,
        kommentar: "",
        userType: 3,
        vorsitzTyp: 0,
        graphUserId: null,
        hatVollzugriff: false,
        sequenz: sequenz,
        autoInvite: false,
      });
      this.state.thirdUser.push({
        id: Guid.parse(Guid.EMPTY),
        displayName: "",
        isActive: false,
      });
      this.state.thirdGender.push({
        id: 1,
        type: "m /w /d",
      });

      this.state.thirdComment.push("");

      sequenz++;
    }

    //looping through SBV
    sequenz = 0;
    for (let i = 0; i < forthloopLength; i++) {
      this.state.forthMembers.push({
        id: Guid.createEmpty(),
        gremiumId: Guid.parse(localStorage.getItem("selectedAuschuss")!),
        geschlecht: 1,
        kommentar: "",
        userType: 4,
        vorsitzTyp: 0,
        graphUserId: null,
        hatVollzugriff: false,
        sequenz: sequenz,
        autoInvite: false,
      });
      this.state.forthUser.push({
        id: Guid.parse(Guid.EMPTY),
        displayName: "",
        isActive: false
      });
      this.state.forthGender.push({
        id: 1,
        type: "m /w /d",
      });
      this.state.forthComment.push("");
    }

    this.setState({ isLoading: false });
    this.checkIsKonfValid()
    sequenz++;
  };

  handleTokenAccessFailure = (error: string) => {
    alert(error);
  };

  generateOptionsForDropdown = () => {
    const optionsWorkers = this.generateOptions(
      this.state.graphUsers,
      (user) => !this.userAlreadyPickedInWorkerCouncil(user))
    this.setState({ optionsWorkerCouncil: optionsWorkers }, this.checkIsKonfValid)
  }

  generateOptionsForSBVDropdown = () => {
    const optionsWorkers = this.generateOptionsSBV(
      this.state.graphUsers,
      (user) => !this.userAlreadyPickedInSBV(user))
    this.setState({ optionsPickedInSBV: optionsWorkers })
  }

  loadAllUsersAsync = async () => {
    let response = await getAllUsersAsync(this.handleTokenAccessFailure);
    if (response.status === StatusCodes.OK && response.data) {
      this.setState({ graphUsers: response.data }, () => (this.loadMitglieder(), this.generateOptionsForDropdown(), this.generateOptionsForSBVDropdown()));
    }
  };

  userAlreadyPickedInWorkerCouncil = (user: IGraphUser) => {
    if (
      this.state.oMembers.findIndex((u) => u.graphUserId === user.id) === -1 &&
      this.state.secondMembers.findIndex((u) => u.graphUserId === user.id) === -1 &&
      this.state.thirdMembers.findIndex((u) => u.graphUserId === user.id) === -1
    ) {
      return false;
    } else {
      return true;
    }
  };

  userAlreadyPickedInSBV = (user: IGraphUser) => {
    if (this.state.forthMembers.findIndex((u) => u.graphUserId === user.id) === -1) {
      return false;
    } else {
      return true;
    }
  };

  handleChangeUserOrdentlicheMitglieder = async (
    i: any,
    user: IGraphUser | undefined
  ) => {
    if (!user) {
      return;
    }
    let oMembers = [...this.state.oMembers];

    let memIndex = { ...oMembers[i] };
    memIndex.graphUserId = user.id;
    if (i === 0) {
      memIndex.vorsitzTyp = 1;
    }
    oMembers[i] = memIndex;
    this.setState({ oMembers });
    this.checkIsKonfValid()

    this.state.sampleUser[i] = user

    if (i === 0 || i === 1) {
      if (!this.state.oMembers[i].hatVollzugriff)
        await patchMitglieder(this.state.oMembers[i].id.toString(), "hatVollzugriff", true, this.handleTokenAccessFailure)
    }

    if (this.state.oMembers[i].graphUserId) {
      await patchMitglieder(this.state.oMembers[i].id.toString(), "graphUserId", user.id.toString(), this.handleTokenAccessFailure)
      return
    }
  };

  checkIsKonfValid = () => {
    if (this.state.oMembers.findIndex((m) => m.graphUserId === Guid.createEmpty().toString()) !== -1 ||
      this.state.secondMembers.findIndex((m) => m.graphUserId === Guid.createEmpty().toString()) !== -1 ||
      this.state.thirdMembers.findIndex((m) => m.graphUserId === Guid.createEmpty().toString()) !== -1 ||
      this.state.forthMembers.findIndex((m) => m.graphUserId === Guid.createEmpty().toString()) !== -1) {
      this.props.konfigurationValid(false)
    } else {
      this.props.konfigurationValid(true)
    }
  }

  onChangeGenderOrdentlicheMitglieder = async (i: any, gender: genderType) => {
    let oMembers = [...this.state.oMembers];
    let memIndex = { ...oMembers[i] };
    memIndex.geschlecht = gender.id;
    oMembers[i] = memIndex;
    this.setState({ oMembers });
    if (this.state.mitgliederObj[i]) {
      await patchMitglieder(this.state.oMembers[i].id.toString(), "geschlecht", gender.id, this.handleTokenAccessFailure)
    }
  };

  onChangeNotiOrdentlicheMitglieder = async (i, event) => {
    this.state.sampleComment[i] = event.target.value
    let oMembers = this.state.oMembers;
    let memIndex = { ...oMembers[i] };
    memIndex.kommentar = event.target.value;
    oMembers[i] = memIndex;

    this.setState({ oMembers }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          if (this.state.mitgliederObj[i]) {
            await patchMitglieder(this.state.oMembers[i].id.toString(), "kommentar", event.target.value, this.handleTokenAccessFailure)
          }
        }, 1000),
      });
    });
  };

  onChangeUserOrGenderOrdentlicheMitglieder = (i, event) => {
    let selectedGender: genderType | undefined = gender.find(
      (g) => g.type === event.target.value
    );
    if (selectedGender) {
      this.onChangeGenderOrdentlicheMitglieder(i, selectedGender);
    }
  };

  onAdminStatusChange = async (i, type, value, user) => {
    const currentLoggedUserId = this.props.teamsContext?.user?.id;
    if (type !== 1) return

    if (currentLoggedUserId === this.state.sampleUser[i].id) {
      this.setState({ adminWarningDialogLoggedUser: true })
    } else {
      this.setState({ adminWarningDialogLoggedUser: false })
    }
    if (
      !value.checked
    ) {
      if (!this.state.shouldRemoveUserRights) {
        this.setState({
          adminWarningDialogOpen: true,
          adminChange: { i: i, type: type, value: value, user: user },
        });
        return;
      }
    }
    let adminStateArray = this.state.sampleHatVollzugriff;
    if (this.state.mitgliederObj[i]) {
      await patchMitglieder(this.state.oMembers[i].id.toString(), "hatVollzugriff", value.checked, this.handleTokenAccessFailure)
    }
    adminStateArray[i] = !adminStateArray[i];

    let oMembers = this.state.oMembers;
    let memIndex = { ...oMembers[i] };
    memIndex.hatVollzugriff = adminStateArray[i];
    oMembers[i] = memIndex;

    this.setState({
      shouldRemoveUserRights: false,
      sampleHatVollzugriff: adminStateArray,
      oMembers: oMembers,
    });
  };

  handleChangeUserErsatzmitglieder = async (i: any, user: IGraphUser | undefined) => {
    if (user === undefined) {
      return;
    }

    let secondMembers = [...this.state.secondMembers];
    let memIndex = { ...secondMembers[i] };

    memIndex.graphUserId = user.id;
    secondMembers[i] = memIndex;
    this.setState({ secondMembers });

    this.checkIsKonfValid()

    this.state.secondUser[i] = user

    if (this.state.secondMembers[i].graphUserId) {
      await patchMitglieder(this.state.secondMembers[i].id.toString(), "graphUserId", user.id.toString(), this.handleTokenAccessFailure)
    }
  };

  onChangeGenderErsatzmitglieder = async (i: any, gender: genderType) => {
    let secondMembers = [...this.state.secondMembers];
    let memIndex = { ...secondMembers[i] };
    memIndex.geschlecht = gender.id;
    secondMembers[i] = memIndex;
    this.setState({ secondMembers });
    if (this.state.mitgliederObj[i]) {
      await patchMitglieder(this.state.secondMembers[i].id.toString(), "geschlecht", gender.id, this.handleTokenAccessFailure)
    }
  };

  onChangeNotiErsatzmitglieder = async (i, event) => {
    this.state.secondComment[i] = event.target.value
    let secondMembers = this.state.secondMembers;
    let memIndex = { ...secondMembers[i] };
    memIndex.kommentar = event.target.value;
    secondMembers[i] = memIndex;

    this.setState({ secondMembers }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          if (this.state.mitgliederObj[i]) {
            await patchMitglieder(this.state.secondMembers[i].id.toString(), "kommentar", event.target.value, this.handleTokenAccessFailure)
          }
        }, 1000),
      });
    });
  };

  onChangeUserOrGenderErsatzmitglieder = (i, event) => {
    let selectedGender: genderType | undefined = gender.find(
      (g) => g.type === event.target.value
    );
    if (selectedGender) {
      this.onChangeGenderErsatzmitglieder(i, selectedGender);
    }
  };

  handleChangeUserSBV = async (i: any, user: IGraphUser | undefined) => {
    if (user === undefined) {
      return;
    }

    let forthMembers = [...this.state.forthMembers];
    let memIndex = { ...forthMembers[i] };

    memIndex.graphUserId = user.id;
    forthMembers[i] = memIndex;
    this.setState({ forthMembers });

    this.checkIsKonfValid()

    this.state.forthUser[i] = user

    if (this.state.forthMembers[i].graphUserId) {
      await patchMitglieder(this.state.forthMembers[i].id.toString(), "graphUserId", user.id.toString(), this.handleTokenAccessFailure)
    }
  };

  onChangeGenderSBV = async (i: any, gender: genderType) => {
    let forthMembers = [...this.state.forthMembers];
    let memIndex = { ...forthMembers[i] };
    memIndex.geschlecht = gender.id;
    forthMembers[i] = memIndex;
    this.setState({ forthMembers });
    if (this.state.mitgliederObj[i]) {
      await patchMitglieder(this.state.forthMembers[i].id.toString(), "geschlecht", gender.id, this.handleTokenAccessFailure)
    }
  };

  onChangeNotiSBV = async (i, event) => {
    this.state.forthComment[i] = event.target.value;
    let forthMembers = this.state.forthMembers;
    let memIndex = { ...forthMembers[i] };
    memIndex.kommentar = event.target.value;
    forthMembers[i] = memIndex;

    this.setState({ forthMembers }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          if (this.state.mitgliederObj[i]) {
            await patchMitglieder(this.state.forthMembers[i].id.toString(), "kommentar", event.target.value, this.handleTokenAccessFailure)
          }
        }, 1000),
      });
    });
  };

  onChangeUserOrGenderSBV = (i, event) => {
    let selectedGender: genderType | undefined = gender.find(
      (g) => g.type === event.target.value
    );
    if (selectedGender) {
      this.onChangeGenderSBV(i, selectedGender);
    }
  };

  handleChangeUserJAV = async (i: any, user: IGraphUser | undefined) => {
    if (user === undefined) {
      return;
    }

    let thirdMembers = [...this.state.thirdMembers];
    let memIndex = { ...thirdMembers[i] };

    memIndex.graphUserId = user.id;
    thirdMembers[i] = memIndex;
    this.setState({ thirdMembers });

    this.checkIsKonfValid()

    this.state.thirdUser[i] = user

    if (this.state.thirdMembers[i].graphUserId) {
      await patchMitglieder(this.state.thirdMembers[i].id.toString(), "graphUserId", user.id.toString(), this.handleTokenAccessFailure)
      return
    }
  };

  onChangeGenderJAV = async (i: any, gender: genderType) => {
    let thirdMembers = [...this.state.thirdMembers];
    let memIndex = { ...thirdMembers[i] };
    memIndex.geschlecht = gender.id;
    thirdMembers[i] = memIndex;
    this.setState({ thirdMembers });
    if (this.state.mitgliederObj[i]) {
      await patchMitglieder(this.state.thirdMembers[i].id.toString(), "geschlecht", gender.id, this.handleTokenAccessFailure)
    }
  };

  onChangeNotiJAV = async (i, event) => {
    this.state.thirdComment[i] = event.target.value;
    let thirdMembers = this.state.thirdMembers;
    let memIndex = { ...thirdMembers[i] };
    memIndex.kommentar = event.target.value;
    thirdMembers[i] = memIndex;

    this.setState({ thirdMembers }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          if (this.state.mitgliederObj[i]) {
            await patchMitglieder(this.state.thirdMembers[i].id.toString(), "kommentar", event.target.value, this.handleTokenAccessFailure)
          }
        }, 1000),
      });
    });
  };

  onChangeUserOrGenderJAV = (i, event) => {
    let selectedGender: genderType | undefined = gender.find(
      (g) => g.type === event.target.value
    );
    if (selectedGender) {
      this.onChangeGenderJAV(i, selectedGender);
    }
  };

  configDataChange = () => {
    this.setState({ configDataChanged: true }, () =>
      sessionStorage.setItem("configDataChanged", "true")
    );
  };

  generateOptions = (users, filterFunction) => {
    const sorted = users.sort((a, b) => a.displayName.localeCompare(b.displayName))
    return sorted
      .filter(filterFunction)
      .map((user, index) => <Option key={index} value={user.displayName}>{`${user.displayName} ${!user.isActive ? "(Inaktiv)" : ''}`}</Option>);
  };

  generateOptionsSBV = (users, filterFunction) => {
    const sorted = users.sort((a, b) => a.displayName.localeCompare(b.displayName))
    return sorted
      .filter(filterFunction)
      .map((user, index) => <Option key={index} value={user.displayName}>{`${user.displayName} ${!user.isActive ? "(Inaktiv)" : ''}`}</Option>);
  };

  onValueChange = (e) => {
    this.generateOptionsForDropdown()
  }

  onValueChangeSBV = (e) => {
    this.generateOptionsForSBVDropdown()
  }

  onChangeMitglieder = (e, i) => {
    const filteredOmembers: IMitgliederGremium[] = []
    this.state.oMembers.filter((mem) => {
      if (mem.graphUserId !== this.state.oMembers[i].graphUserId) {
        filteredOmembers.push(mem)
      } else {
        filteredOmembers.push({
          id: mem.id,
          gremiumId: mem.gremiumId,
          geschlecht: 1,
          kommentar: "",
          userType: 1,
          vorsitzTyp: 0,
          graphUserId: Guid.createEmpty(),
          hatVollzugriff: false,
          sequenz: i + 1,
          autoInvite: mem.autoInvite
        })
      }
    })
    this.setState({ oMembers: filteredOmembers })
    const optionsWorkers = this.generateOptions(
      this.state.graphUsers,
      (user) => !this.userAlreadyPickedInWorkerCouncil(user))
    this.setState({ optionsWorkerCouncil: optionsWorkers })
  }

  onChangeErsatzMitglieder = (e, i) => {
    const filteredSecondMembers: IMitgliederGremium[] = []
    this.state.secondMembers.filter((mem) => {
      if (mem.graphUserId !== this.state.secondMembers[i].graphUserId) {
        filteredSecondMembers.push(mem)
      } else {
        filteredSecondMembers.push({
          id: mem.id,
          gremiumId: mem.gremiumId,
          geschlecht: 1,
          kommentar: "",
          userType: 2,
          vorsitzTyp: 0,
          graphUserId: Guid.createEmpty(),
          hatVollzugriff: false,
          sequenz: i + 1,
          autoInvite: mem.autoInvite,
        })
      }
    })

    this.setState({ secondMembers: filteredSecondMembers })
    const optionsWorkers = this.generateOptions(
      this.state.graphUsers,
      (user) => !this.userAlreadyPickedInWorkerCouncil(user))
    this.setState({ optionsWorkerCouncil: optionsWorkers })
  }

  onChangeJAV = (e, i) => {
    const filteredThirdMembers: IMitgliederGremium[] = []
    this.state.thirdMembers.filter((mem) => {
      if (mem.graphUserId !== this.state.thirdMembers[i].graphUserId) {
        filteredThirdMembers.push(mem)
      } else {
        filteredThirdMembers.push({
          id: mem.id,
          gremiumId: mem.gremiumId,
          geschlecht: 1,
          kommentar: "",
          userType: 3,
          vorsitzTyp: 0,
          graphUserId: Guid.createEmpty(),
          hatVollzugriff: false,
          sequenz: i + 1,
          autoInvite: mem.autoInvite
        })
      }
    })
    this.setState({ thirdMembers: filteredThirdMembers })
    const optionsWorkers = this.generateOptions(
      this.state.graphUsers,
      (user) => !this.userAlreadyPickedInWorkerCouncil(user))
    this.setState({ optionsWorkerCouncil: optionsWorkers })
  }

  onChangeSBV = (e, i) => {
    const filteredFourthMembers: IMitgliederGremium[] = []
    this.state.forthMembers.filter((mem) => {
      if (mem.graphUserId !== this.state.forthMembers[i].graphUserId) {
        filteredFourthMembers.push(mem)
      } else {
        filteredFourthMembers.push({
          id: mem.id,
          gremiumId: mem.gremiumId,
          geschlecht: 1,
          kommentar: "",
          userType: 4,
          vorsitzTyp: 0,
          graphUserId: Guid.createEmpty(),
          hatVollzugriff: false,
          sequenz: i + 1,
          autoInvite: mem.autoInvite,
        })
      }
    })
    this.setState({ forthMembers: filteredFourthMembers })
    const optionsWorkersSBV = this.generateOptions(
      this.state.graphUsers,
      (user) => !this.userAlreadyPickedInSBV(user))
    this.setState({ optionsPickedInSBV: optionsWorkersSBV })
  }

  setName = (name): string => {
    if (name) {
      if (name.displayName === "") return ""
      if (name.isActive) {
        return name.displayName
      }
      return `${name.displayName} (Inaktiv)`
    }
    else return ""
  }

  getLabel = (i, type) => {
    if (type === 1) {
      if (i === 0) return 'Vorsitzender'
      if (i === 1) return 'Stellv. Vorsitzender'
      if (i > 1) return 'Mitglied'
    } else if (type === 2) {
      return "Ersatzmitglied"
    } else if (type === 3) {
      if (i === 0) { return 'JAV-Vors' }
      if (i === 1) { return 'stellv. JAV-Vors' }
      if (i > 1) { return 'Mitglied' }
    } else if (type === 4) {
      if (i === 0) { return 'Vertrauensperson' }
      if (i === 1) { return '1.Stellvertreter' }
      if (i > 1) { return 'Stellvertreter' }
    }
  }

  handleUserInviteChange = async (id, value, type, i) => {
    const checkedValue = value.target.checked

    if (value.target.checked) {
      if (type === 1) {
        this.setState(prevState => {
          const newMembers = [...prevState.oMembers];
          newMembers[i].autoInvite = checkedValue;
          return { oMembers: newMembers };
        })
      } else if (type === 2) {
        this.state.secondMembers[i].autoInvite = checkedValue
      } else if (type === 3) {
        this.state.thirdMembers[i].autoInvite = checkedValue
      } else if (type === 4) {
        this.state.forthMembers[i].autoInvite = checkedValue
      }
      await patchMitglieder(id, "autoInvite", checkedValue, this.handleTokenAccessFailure)
      return
    }

    this.state.confirmDialogRef.current.showConfirmDialog(ConfirmCheckboxChange, async () => {
      this.handleConfirm(i, type, checkedValue, id)
    })
  }

  handleConfirm = async (i, type, checkedValue, id) => {
    if (type === 1) {
      this.handleChangeState('oMembers', i, checkedValue)
    } else if (type === 2) {
      this.handleChangeState('secondMembers', i, checkedValue)
    } else if (type === 3) {
      this.handleChangeState('thirdMembers', i, checkedValue)
    } else if (type === 4) {
      this.handleChangeState('forthMembers', i, checkedValue)
    }
    await patchMitglieder(id, "autoInvite", checkedValue, this.handleTokenAccessFailure)
  }

  handleChangeState = (members, i, checkedValue) => {
    this.setState(prevState => {
      const tempState = prevState
      const newMembers = [...prevState[members]];
      newMembers[i].autoInvite = checkedValue;
      tempState[members] = newMembers
      return tempState;
    })
  }


  renderUserField = (members, type) => {
    let comment, gender, user
    if (type === 1) {
      comment = this.state.sampleComment
      gender = this.state.sampleGender
      user = this.state.sampleUser
    } else if (type === 2) {
      comment = this.state.secondComment
      gender = this.state.secondGender
      user = this.state.secondUser
    } else if (type === 3) {
      comment = this.state.thirdComment
      gender = this.state.thirdGender
      user = this.state.thirdUser
    } else if (type === 4) {
      comment = this.state.forthComment
      gender = this.state.forthGender
      user = this.state.forthUser
    }

    {
      return members.map(
        (e, i) => (
          <span key={i}>
            <div
              style={{
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <Field
                label={this.getLabel(i, type)}
                style={{ width: "100%", marginBottom: "1rem" }}
                onInput={(e) => {
                  type === 1 && this.onChangeUserOrGenderOrdentlicheMitglieder(i, e)
                  type === 2 && this.onChangeUserOrGenderErsatzmitglieder(i, e)
                  type === 3 && this.onChangeUserOrGenderJAV(i, e)
                  type === 4 && this.onChangeUserOrGenderSBV(i, e)
                }
                }
                onChange={(e) => {
                  type === 1 && this.onChangeMitglieder(e, i)
                  type === 2 && this.onChangeErsatzMitglieder(e, i)
                  type === 3 && this.onChangeJAV(e, i)
                  type === 4 && this.onChangeSBV(e, i)
                }
                }
                onClick={(e) => {
                  type === 4 ? this.onValueChangeSBV(e) : this.onValueChange(e)
                }}
              >
                <div className="Konfiguration-formRow">
                  <CustomCombobox
                    defaultValue={this.setName(user[i])}
                    onSelectFunction={(data) => {
                      type === 1 && this.handleChangeUserOrdentlicheMitglieder(i, this.state.graphUsers.find((u) => u.displayName === data.optionValue));
                      type === 2 && this.handleChangeUserErsatzmitglieder(i, this.state.graphUsers.find((u) => u.displayName === data.optionValue));
                      type === 3 && this.handleChangeUserJAV(i, this.state.graphUsers.find((u) => u.displayName === data.optionValue));
                      type === 4 && this.handleChangeUserSBV(i, this.state.graphUsers.find((u) => u.displayName === data.optionValue));
                    }}
                    options={type === 4 ? this.state.optionsPickedInSBV : this.state.optionsWorkerCouncil}
                    userHasUpdatePermission={
                      this.props.userHasUpdatePermission
                    }
                  />
                  <RadioGroup
                    valueArray={gender_options}
                    value={gender[i]?.type}
                    disabled={!this.props.userHasUpdatePermission}
                  />
                  <Checkbox
                    onChange={(e, data) => {
                      this.onAdminStatusChange(i, 1, data, user[i]);
                    }}
                    className={
                      i <= 1
                        ? "checkbox__isAdmin hidden"
                        : "checkbox__isAdmin"
                    }
                    checked={(i === 0 || i === 1) && type === 1 ? true : type === 1 ? this.state.sampleHatVollzugriff[i] : false}
                    label={<span>Schreibrechte</span>}
                    disabled={type > 1 && !this.props.userHasUpdatePermission || i === 0 || i === 1}
                  />
                </div>
              </Field>
              <div style={{ display: "flex" }}>
                <Field style={{ width: "100%" }}>
                  <Input
                    data-id={user[i]?.id}
                    className="Konfiguration-notizInput "
                    value={comment[i]}
                    onChange={(evt) => {
                      type === 1 && this.onChangeNotiOrdentlicheMitglieder(i, evt)
                      type === 2 && this.onChangeNotiErsatzmitglieder(i, evt)
                      type === 3 && this.onChangeNotiJAV(i, evt)
                      type === 4 && this.onChangeNotiSBV(i, evt)
                    }
                    }
                    placeholder="Notiz"
                    autoComplete="false"
                    disabled={!this.props.userHasUpdatePermission}
                  />
                </Field>
                <Field>
                  <Checkbox
                    style={{ minWidth: "230px" }}
                    onChange={(evt) => {
                      this.handleUserInviteChange(e.id, evt, type, i);
                    }}
                    checked={e.autoInvite}
                    label={<>Zu jeder Sitzung einladen <Tooltip
                      relationship="label"
                      content={
                        type === 4 ? 'Dieser Haken sollte für die Vertrauensperson immer gesetzt bleiben. Ausnahme: Sie fällt länger aus und möchte nicht geladen werden.' :
                          type === 3 ? 'Dieser Haken sollte für den JAV-Vorsitzenden immer gesetzt bleiben. Ausnahme: Er fällt länger aus und möchte nicht geladen werden.' :
                            'Dieser Haken sollte für die ordentlichen Mitglieder immer gesetzt bleiben. Ausnahme: Das Mitglied fällt länger aus und möchte nicht geladen werden.'}
                    ><span><InfoIcon /></span>
                    </Tooltip></>}
                    disabled={!this.props.userHasUpdatePermission}
                  /></Field></div>
            </div>
            <div className="margins"></div>
          </span>
        )
      )
    }
  }

  render = () => {
    return (
      <div className="container-div">
        {/* <StatusBar notification={this.state.notification} /> */}
        <ConfirmDialog ref={this.state.confirmDialogRef} />
        <div className="konfig-box">
          <MessageBar
            icon={
              <span
                style={{ fontSize: "40px" }}
              ><InfoIcon /></span>
            }
            intent="info"
            style={{
              width: "100%",
              textAlign: "left",
              padding: "20px 30px",
              margin: "10px 0 20px 0",
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >Tragen Sie hier die Namen der Gremiumsmitglieder ein, wählen Sie das Geschlecht aus und ergänzen Sie bei Bedarf eine zusätzliche Notiz im Freitextfeld.
            Als Notiz können Sie z.B. Funktionen (weitere Vertretungsreihenfolge nach BRV und stellv. BRV, Protokollführer usw.) oder die Zugehörigkeit zu Listen aufnehmen.
          </MessageBar>
        </div>
        {
          this.state.isLoading ? (
            <Spinner
              style={{
                width: "100%",
                height: "100vh",
                position: "fixed",
                top: "0",
                left: "0",
              }}
              className="loader"
              label={LoaderMessage}
            />
          ) : (
            <div
              className="konfiguration__settings-form"
              style={{ justifyContent: "normal" }}
              ref={this.state.formRef}
              onInput={this.configDataChange}
            >
              <Dialog
                open={this.state.adminWarningDialogOpen}
              >
                <DialogSurface>
                  <DialogTitle style={{ display: "flex", justifyContent: "end" }}>
                    <span title="Schließen" onClick={() => this.setState({ adminWarningDialogOpen: false })}>
                      <CloseIcon />
                    </span>
                  </DialogTitle>
                  <div className="Konfiguration-admin-delete-modal">
                    {this.state.adminWarningDialogLoggedUser ?
                      <div>
                        Achtung: Sind Sie sicher, dass Sie sich das Schreibrecht nehmen
                        möchten? <br></br> Sie können danach nur noch beschränkt auf
                        die App zugreifen!
                      </div>
                      : <div>
                        Sind Sie sicher, dass Sie das Schreibrecht entziehen möchten?
                        <br></br>
                        Das Mitglied kann danach nur noch beschränkt auf die App zugreifen!
                      </div>
                    }
                    <div className="Konfiguration-mitglieder-admin">
                      <Button
                        style={{ width: "25px" }}
                        onClick={() => {
                          const { i, type, user, value } = this.state.adminChange;
                          this.setState(
                            {
                              adminWarningDialogOpen: false,
                              shouldRemoveUserRights: true,
                            },
                            () => this.onAdminStatusChange(i, type, value, user)
                          );
                        }}
                      >
                        Ja
                      </Button>
                      <Button
                        style={{ width: "25px" }}
                        onClick={() => {
                          this.setState({ adminWarningDialogOpen: false });
                        }}
                      >
                        Nein
                      </Button>
                    </div>
                  </div></DialogSurface></Dialog>
              <div className="Konfiguration-formSection">
                {this.state.anzahlMitgliederKonfiguration > 0 && (
                  <h3 className="Konfiguration-formSectionTitle">
                    Ordentliche Mitglieder
                  </h3>
                )}
                {/* render fields for Ordentliche Mitglieder */}
                {this.renderUserField(this.state.oMembers, 1)}
              </div>
              <div className="Konfiguration-formSection">
                {this.state.anzahlErsatzmitgliederKonfiguration > 0 && (
                  <h3 className="Konfiguration-formSectionTitle">
                    Ersatzmitglieder
                  </h3>
                )}
                {/* render fields for Ersatzmitglieder */}
                {this.renderUserField(this.state.secondMembers, 2)}
              </div>
              <div className="Konfiguration-formSection">
                {this.state.anzahlSbvKonfiguration > 0 && (
                  <h3 className="Konfiguration-formSectionTitle">
                    Schwerbehindertenvertretung
                  </h3>
                )}
                {/* render fields for SBV */}
                {this.renderUserField(this.state.forthMembers, 4)}
              </div>
              <div className="Konfiguration-formSection">
                {this.state.anzahlJavKonfiguration > 0 && (
                  <h3 className="Konfiguration-formSectionTitle">
                    Jugend und Auszubildendenvertretung
                  </h3>
                )}
                {/* render fields for JAV */}
                {this.renderUserField(this.state.thirdMembers, 3)}
              </div>
              <div
                className="konfiguration__save-btn-wrapper"
                hidden={this.state.isLoading}
              >
                <Button appearance="secondary" onClick={() => this.props.navMenuItemClicked(Navigation.Gremium)}>Zurück</Button>
                <Button appearance="primary" onClick={() => this.props.navMenuItemClicked(Navigation.Tagesordnung)}>Weiter</Button>
              </div>
            </div>
          )
        }
      </div >
    );
  };
}

export default KonfigurationMitglieder;
