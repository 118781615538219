import * as React from "react";
import { Button, Divider, Tooltip } from "@fluentui/react-components";

import "./sitzung-table.scss";
import { NeueSitzungDisabledGremiumMembersMissingInConfig, NeueSitzungDisabledNoPermissions } from "../../../constants/textLabels";
import {
  ISitzungListProps,
  ISitzungListState,
} from "../../../interfaces/sitzungen/sitzungen-table.interface";
import { SortedTable } from "./sitzung-sorted-table";
import moment from "moment";
import { AddIcon, FilterResetIcon, LastSitzungIcon, NextIcon } from "../../../utils/icons";

const defaultActiveFilters = {
  dateFilter: "",
  artFilter: "",
  formatFilter: "",
  nameFilter: "",
};
class SitzungTable extends React.Component<
  ISitzungListProps,
  ISitzungListState
> {
  constructor(props) {
    super(props);
    const storageFilters = localStorage.getItem("activeFilters");
    this.state = {
      resetDropdowns: false,
      tableRows: props.tableRows,
      filteredData: props.tableRows,
      activeFilters: JSON.parse(storageFilters!) || defaultActiveFilters,
    };
  }

  componentDidMount(): void {
    this.filterColumn();
  }

  handleClickOutside = (e) => {
    let parent = e.target;

    if (parent.classList?.contains("dropdownMenuTrigger")) {
      return;
    }

    while (
      parent !== null &&
      parent !== document.body &&
      !parent.classList?.contains("menu__sort-filter")
    ) {
      parent = parent.parentNode;
    }

    if (parent !== null && parent.classList?.contains("menu__sort-filter")) {
      return;
    }

    let menus = document.querySelectorAll(".menu__sort-filter");
    menus.forEach((menu) => {
      menu.setAttribute("hidden", "");
    });
  };

  filterColumn = () => {
    localStorage.setItem(
      "activeFilters",
      JSON.stringify(this.state.activeFilters)
    );
    let newFilteredData = this.props.tableRows;
    if (this.state.activeFilters.dateFilter !== "") {
      newFilteredData = newFilteredData.filter((row) =>
        moment(row.item.sitzungDate)
          .format("DD.MM.YYYY")
          .includes(this.state.activeFilters.dateFilter)
      );
    }
    if (this.state.activeFilters.nameFilter !== "") {
      newFilteredData = newFilteredData.filter((row) =>
        row.item.sitzungName
          .toString()
          .toLowerCase()
          .includes(this.state.activeFilters.nameFilter.toLowerCase())
      );
    }
    if (this.state.activeFilters.artFilter !== "") {
      newFilteredData = newFilteredData.filter((row) =>
        row.item.sitzungArt
          .toString()
          .includes(this.state.activeFilters.artFilter)
      );
    }
    if (this.state.activeFilters.formatFilter !== "") {
      newFilteredData = newFilteredData.filter((row) =>
        row.item.sitzungFormat
          .toString()
          .toLowerCase()
          .includes(this.state.activeFilters.formatFilter.toLowerCase())
      );
    }
    this.setState((prevState) => ({
      ...prevState,
      filteredData: newFilteredData,
    }));
  };

  handleInputChange = (value: string, id: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        activeFilters: {
          ...prevState.activeFilters,
          [id]: value,
        },
      }),
      () => this.filterColumn()
    );
  };

  resetFilters = () => {
    localStorage.removeItem("activeFilters");
    this.setState({ activeFilters: defaultActiveFilters, resetDropdowns: !this.state.resetDropdowns }, () =>
      this.filterColumn()
    );
  };

  render = () => {
    return (
      <>
        <div className="controll-toolbar">
          {this.props.hasPermissionToCreate && this.props.councilMembersInserted ? <Button
            disabledFocusable={!this.props.canOpenNewSitzung}
            appearance="secondary"
            icon={
              <AddIcon />}
            onClick={() => this.props.openNewSitzung()}
          >Neue Sitzung</Button> :
            <Tooltip
              content={
                !this.props.hasPermissionToCreate ? NeueSitzungDisabledNoPermissions :
                  !this.props.councilMembersInserted
                    ? NeueSitzungDisabledGremiumMembersMissingInConfig
                    : ''
              }
              relationship="label"
            ><Button
              disabledFocusable={!this.props.canOpenNewSitzung}
              appearance="secondary"
              icon={<AddIcon />}
              onClick={() => this.props.openNewSitzung()}
            >Neue Sitzung</Button></Tooltip>}
          <Button
            disabled={!this.props.canOpenNextSitzung}
            appearance="secondary"
            icon={<NextIcon />}
            onClick={() => this.props.openNextSitzung()}
          >Nächste Sitzung</Button>
          <Button
            disabled={!this.props.canOpenLastSitzung}
            appearance="secondary"
            icon={<LastSitzungIcon />}
            onClick={() => this.props.openLastSitzung()}
          >Letzte Sitzung</Button>
          <Button
            className="filter-reset-button"
            appearance="secondary"
            icon={<FilterResetIcon />}
            onClick={this.resetFilters}
          >Alle Filter zurücksetzen</Button>
        </div>
        <Divider />
        <SortedTable
          resetDropdowns={this.state.resetDropdowns}
          filteredData={this.state.filteredData}
          handleInputChange={this.handleInputChange}
          state={this.state.activeFilters}
        ></SortedTable>
        {this.state.filteredData.length === 0 && (
          <p style={{ textAlign: "center" }}>Keine Sitzungen</p>
        )}
      </>
    );
  };
}

export default SitzungTable;
