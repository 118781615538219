import { Button, Checkbox, MessageBar, Spinner, Dropdown, Option, Input, Field } from "@fluentui/react-components";
import { Guid } from "guid-typescript";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Constants, { Themes } from "../../constants/constants";
import { ActivityStatus } from "../../models/activity-status";
import IRegistration from "../../models/registration/registration-create";
import "./registration-form.scss";
import { createRegistration } from "../../api/registration-api";
import { getJoinedTeams } from "../../api/teams-api";
import { StatusCodes } from "http-status-codes";
import {
  LoaderMessage,
  RegistrationUnsuccessfulMessage,
} from "../../constants/textLabels";
import { IRegistrationFormState } from "../../interfaces/registration/registration-form.interface";
import { InfoIcon } from "../../utils/icons";

interface IRegistrationFormProps extends RouteComponentProps { }

class RegistrationForm extends React.Component<
  IRegistrationFormProps,
  IRegistrationFormState
> {
  theme: string = Themes.default;

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      isMobileView: window.outerWidth <= Constants.maxWidthForMobileView,
      formRef: React.createRef(),
      firmenname: "",
      gremiumname: "",
      vorname: "",
      nachname: "",
      email: "",
      termsOfUseAccepted: false,
      privacyPolicyAccepted: false,
      notification: { id: 0, message: "", type: ActivityStatus.None },
      validationConducted: false,
      teams: [],
      teamDisplayName: "",
      defaultTeamId: Guid.createEmpty(),
    };
  }

  componentDidMount() {
    this.loadTeams();
    // this.handleTokenAccessFailure(RegistrationUnsuccessfulMessage); TODO:Could be deleted
  }

  loadTeams = async () => {
    const response = await getJoinedTeams(this.handleTokenAccessFailure);
    if (response.status === StatusCodes.OK && response.data) {
      this.setState({ teams: response.data });
    }
  };

  firmennameChanged = (e) => {
    this.setState({
      firmenname: e.target.value,
    });
  };

  gremiumnameChanged = (e) => {
    this.setState({
      gremiumname: e.target.value,
    });
  };

  vornameChanged = (e) => {
    this.setState({
      vorname: e.target.value,
    });
  };

  nachnameChanged = (e) => {
    this.setState({
      nachname: e.target.value,
    });
  };

  emailChanged = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  termsOfUseCbChanged = (e) => {
    this.setState({
      termsOfUseAccepted: !this.state.termsOfUseAccepted,
    });
  };

  privacyPolicyCbChanged = (e) => {
    this.setState({
      privacyPolicyAccepted: !this.state.privacyPolicyAccepted,
    });
  };

  onTeamChange = (_, v) => {
    const teamDisplayName = v.optionValue;
    const defaultTeamId = teamDisplayName
      ? this.state.teams.find((t) => t.displayName === teamDisplayName)?.id
      : undefined;
    if (defaultTeamId) {
      this.setState({
        defaultTeamId: defaultTeamId,
        teamDisplayName: teamDisplayName,
      });
    }
  };

  isFormValid = () => {
    if (
      this.state.firmenname === "" ||
      this.state.gremiumname === "" ||
      this.state.vorname === "" ||
      this.state.nachname === "" ||
      this.state.email === "" ||
      this.validateEmail(this.state.email) === false ||
      this.state.termsOfUseAccepted === false ||
      this.state.teamDisplayName === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  submitForm = async (e) => {
    this.setState({
      validationConducted: true,
      isLoading: true,
    });

    if (this.isFormValid() === false) {
      this.setState({
        isLoading: false,
      });
      return;
    }

    const registrationDetails: IRegistration = {
      firmenname: this.state.firmenname,
      gremiumsname: this.state.gremiumname,
      vorname: this.state.vorname,
      nachname: this.state.nachname,
      mail: this.state.email,
      acceptTOU: this.state.termsOfUseAccepted,
      acceptDPA: this.state.privacyPolicyAccepted,
      defaultTeamId: this.state.defaultTeamId,
    };
    localStorage.removeItem("selectedAuschuss")
    let response = await createRegistration(registrationDetails, () =>
      this.handleTokenAccessFailure(RegistrationUnsuccessfulMessage)
    );

    if (response.status === 201 || response.status === 200) {
      this.setState({ isLoading: false }, () => window.location.reload());
    }
  };

  private handleTokenAccessFailure = (error: string) => {
    this.setState((prevState: IRegistrationFormState) => ({
      notification: {
        id: prevState.notification.id + 1,
        message: error,
        type: ActivityStatus.Error,
      },
    }));
  };

  private validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  private getEmailErrorMessage = () => {
    const { validationConducted, email } = this.state;

    if (validationConducted) {
      if (email === "") {
        return "E-Mail darf nicht leer sein.";
      } else if (!this.validateEmail(email)) {
        return "E-Mail nicht gültig.";
      }
    }

    return "";
  };

  /**
   * Renders the component
   */
  render = () => {
    return (
      <div>
        <div className="page-content">
          {/* <StatusBar notification={this.state.notification} /> */}
          {this.state.isLoading ? (
            <Spinner
              style={{
                width: "100%",
                height: "100vh",
                position: "fixed",
                top: "0",
                left: "0",
              }}
              className="loader"
              label={LoaderMessage}
            />
          ) : (
            <div className="form__registration" ref={this.state.formRef}>
              <MessageBar
                className="form__registration_alert"
                icon={
                  <span
                    style={{ fontSize: "40px" }}
                  ><InfoIcon /></span>
                }
                intent="info"
                style={{
                  width: "100%",
                  padding: "20px 30px",
                  margin: "10px 0 20px 0",
                  display: "flex",
                  gap: "15px",
                }}
              >Schön, dass Sie sich für die BR360, Ihren kostenlosen Sitzungsmanager, entschieden haben. Bevor Sie die erste Sitzung planen können, benötigen wir noch ein paar Informationen, um die App für Sie einzurichten. Bitte beachten Sie: Die gewählte Person des Ansprechpartners wird erster Administrator mit allen Rechten in der App. Das lässt sich später ändern. Nach einem Klick auf den Registrieren-Button dauert es bis zu einer Minute, bis die App eingerichtet ist.</MessageBar>
              <Field
                label={"Firmenname"}
                className="form__registration_wide-input field-style"
                id="firma-name"
                validationMessage={this.state.firmenname === "" &&
                  this.state.validationConducted ?
                  "Firmenname darf nicht leer sein." : ''}
                validationState={this.state.firmenname === "" &&
                  this.state.validationConducted ?
                  "error" : 'none'}
                required
              >
                <Input
                  placeholder="Name der Firma"
                  value={this.state.firmenname}
                  onChange={(e) => this.firmennameChanged(e)}
                />
              </Field>
              <Field
                label={"Gremiumname"}
                className="form__registration_wide-input field-style"
                id="gremium-name"
                validationMessage={this.state.gremiumname === "" &&
                  this.state.validationConducted ?
                  "Gremiumname darf nicht leer sein." : ''}
                validationState={this.state.gremiumname === "" &&
                  this.state.validationConducted ?
                  "error" : 'none'}
                required
              >
                <Input
                  placeholder="Name des Gremiums"
                  value={this.state.gremiumname}
                  onChange={(e) => this.gremiumnameChanged(e)}
                />
              </Field>
              <div className="field-flex">
                <Field
                  label={"Vorname"}
                  className="field-style" id="vorname"
                  validationMessage={this.state.vorname === "" &&
                    this.state.validationConducted ?
                    "Vorname darf nicht leer sein." : ''}
                  validationState={this.state.vorname === "" &&
                    this.state.validationConducted ?
                    "error" : 'none'}
                  required>
                  <Input
                    placeholder="Vorname des Ansprechpartners"
                    value={this.state.vorname}
                    onChange={(e) => this.vornameChanged(e)}
                  />
                </Field>
                <Field
                  className="form__registration_narroew-input field-style"
                  label="Nachname"
                  id="nachname"
                  validationMessage={this.state.nachname == "" &&
                    this.state.validationConducted ?
                    "Nachname darf nicht leer sein." : ""}
                  validationState={this.state.nachname == "" &&
                    this.state.validationConducted ?
                    "error" : "none"}
                  required
                >
                  <Input
                    placeholder="Nachname des Ansprechpartners"
                    value={this.state.nachname}
                    onChange={(e) => this.nachnameChanged(e)}
                  />
                </Field>
              </div>
              <Field
                label={"E-Mail"}
                className="form__registration_wide-input field-style"
                id="mail"
                validationMessage={this.state.validationConducted && this.state.email === '' ?
                  "E-Mail darf nicht leer sein." : (this.state.email !== '' && !this.validateEmail(this.state.email)) ? "E-Mail nicht gültig." : ''
                }
                validationState={this.state.validationConducted && (this.state.email === '' || !this.validateEmail(this.state.email)) ?
                  "error" : 'none'
                }
                required
              >
                <Input
                  placeholder="E-Mail-Adresse des Ansprechpartners"
                  value={this.state.email}
                  onChange={(e) => this.emailChanged(e)}
                />
              </Field>
              <Field label={"Standard Team"} className="form__registration_wide-input field-style"
                validationMessage={this.state.teamDisplayName === "" &&
                  this.state.validationConducted ?
                  "Standard Team darf nicht leer sein." : ''}
                validationState={this.state.teamDisplayName === "" &&
                  this.state.validationConducted ?
                  "error" : 'none'}
                required>
                <Dropdown
                  value={this.state.teamDisplayName}
                  onOptionSelect={this.onTeamChange}
                  placeholder="Suche nach Team"
                >{this.state.teams.map((team) => <Option key={team.id.toString()} value={team.displayName}>{team.displayName}</Option>)}</Dropdown>
              </Field>
              <Field
                className="form__registration_checkbox-input field-style"
                id="tou"
                validationMessage={this.state.termsOfUseAccepted == false &&
                  this.state.validationConducted ?
                  "Die Nutzungsbedingungen müssen akzeptiert werden." : ""}
                validationState={this.state.termsOfUseAccepted == false &&
                  this.state.validationConducted ?
                  "error" : "none"}
                required
              >
                <Checkbox
                  checked={this.state.termsOfUseAccepted}
                  onChange={(e) => this.termsOfUseCbChanged(e)}
                  label={
                    <span>
                      Ich stimme den
                      <a
                        rel="noreferrer"
                        href="https://www.ifb.de/service/betriebsrat360#nutzungsbedingungen"
                        target="_blank"
                      >
                        {" "}
                        Nutzungsbedingungen{" "}
                      </a>
                      zu
                    </span>
                  }
                />
              </Field>
              <span>
                Hier geht`s zu den{" "}
                <a
                  rel="noreferrer"
                  href="https://www.ifb.de/service/betriebsrat360#datenschutz"
                  target="_blank"
                >
                  Datenschutzhinweise
                </a>
              </span>
              <div className="register-btn">
                <Button
                  appearance="primary"
                  onClick={(e) => this.submitForm(e)}
                >Registrieren</Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default RegistrationForm;
