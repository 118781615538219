import { AxiosResponse } from "axios";
import { RegistrationState } from "../enums/registration-state.enum";
import IRegistration from "../models/registration/registration-create";
import axios from "./axios-decorator";

export const createRegistration = async (registrationDetails: IRegistration, handleTokenAccessFailure: (error: string) => void) => {
    let url = '/api/registration';
    return await axios.post(url, handleTokenAccessFailure, registrationDetails);
};

export const getRegistrationState = async (handleTokenAccessFailure: (error: string) => void) => {
    let url = '/api/registration/state';
    let result: AxiosResponse<RegistrationState> = (await axios.get(url, handleTokenAccessFailure));
    return result;
};