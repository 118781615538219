import { MsalContext, MsalProvider } from "@azure/msal-react";
import { Textarea, Spinner, Dropdown, Option, Button, Tooltip, Field, MessageBar } from "@fluentui/react-components";
import { Guid } from "guid-typescript";
import { StatusCodes } from "http-status-codes";
import querystring from "query-string";
import * as React from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import {
  getAttendanceListRuckmeldung,
  getEinladungsadressatDto,
  getSitzungDetails,
  updateUserResponse,
} from "../../api/einladungsrückmeldung-api";
import { msalConfig, protectedResources } from "../../authConfig";
import Constants, { Themes } from "../../constants/constants";
import { EinladungsrückmeldungStatus } from "../../enums/einladungsrückmeldungstatus.enum";
import { Teilnahmeform } from "../../enums/teilnahmeform.enum";
import { Verhinderungsgrund } from "../../enums/verhinderungsgrund.enum";
import IPermission from "../../models/IPermission";
import { IWithContext } from "../../providers/context-provider";
import { BrowserSignInButton } from "../browser/browser-signin-button";
import "./einladungsrueckmeldung.scss";
import { LoaderMessage, RequiredFieldErrorMessage } from "../../constants/textLabels";
import Footer from "../common/footer/Footer";
import Logo from "../../assets/color_icon.png";

import IAddressatenResponse from "../../models/einladung/addressatenResponse";
import { InfoIcon } from "../../utils/icons";
import { PublicClientApplication } from "@azure/msal-browser";

interface IEinladungsrueckmeldungState {
  isLoading: boolean;
  isMobileView: boolean;

  sitzungsId: Guid;
  einladungsId: Guid;
  formType: { id: Teilnahmeform; type: string };
  statusType: { id: EinladungsrückmeldungStatus; type: string };
  verhinderungsgrundType: { id: Verhinderungsgrund; type: string };
  kommentar: string;

  rückmeldungStatus: number;
  rückmeldungTeilnahmeForm: number;
  rückmeldungVerhinderungsgrund: number;
  sent: boolean;
  formValid: boolean | null;
  showMessage: boolean;

  currentRückmeldung: IAddressatenResponse;
  userPermissions: IPermission[];
  isInvited: boolean | undefined;
  sitzungsName: string;
  sitzungenStarted: boolean;
  anweseheitslisteId: string;
  gremiumId: string;
  addressatenId: string;
  showErrorMessage: boolean
}

interface IEinladungsrueckmeldungProps
  extends WithTranslation,
  IWithContext,
  RouteComponentProps { }

const rückmeldungStatusType = [
  { id: Number(EinladungsrückmeldungStatus.Keine), type: "" },
  { id: Number(EinladungsrückmeldungStatus.Zusage), type: "Zusage" },
  { id: Number(EinladungsrückmeldungStatus.Absage), type: "Absage" },
];

const teilnahmeFormType = [
  { id: Number(Teilnahmeform.Default), type: "" },
  { id: Number(Teilnahmeform.Präsenz), type: "Präsenz" },
  { id: Number(Teilnahmeform.Online), type: "Online" },
  { id: Number(Teilnahmeform.Hybrid), type: "Hybrid" }
];

const rückmeldungVerhinderungsgrundType = [
  {
    id: -1,
    type: "",
  },
  {
    id: Number(Verhinderungsgrund.Krankheit_Kur_Elternzeit),
    type: "Krankheitsbedingte Arbeitsunfähigkeit/Elternzeit/Kur",
  },
  { id: Number(Verhinderungsgrund.Urlaub), type: "Erholungsurlaub" },
  {
    id: Number(Verhinderungsgrund.Montage_Dienstreise),
    type: "Dienstreise/Montage",
  },
  {
    id: Number(Verhinderungsgrund.Schulung_Fortbildung),
    type: "Schulung/Fortbildung",
  },
  {
    id: Number(Verhinderungsgrund.Sonstige),
    type: "sonstiger dringender Verhinderungsgrund",
  },
  { id: Number(Verhinderungsgrund.Keine), type: "Kein Verhinderungsgrund" },
];

const DROPDOWN_STYLE = {
  width: "100%"
}

const msalInstance = new PublicClientApplication(msalConfig);


class Einladungsrueckmeldung extends React.Component<
  IEinladungsrueckmeldungProps,
  IEinladungsrueckmeldungState
> {
  static contextType = MsalContext;
  theme: string = Themes.default;
  context!: React.ContextType<typeof MsalContext>

  /**
   * Constructor which initializes state.
   */
  constructor(props: any) {
    super(props);
    this.state = {
      showErrorMessage: false,
      addressatenId: '',
      gremiumId: '',
      anweseheitslisteId: '',
      sitzungenStarted: false,
      isLoading: false,
      isInvited: undefined,
      isMobileView: window.outerWidth <= Constants.maxWidthForMobileView,
      sitzungsId: Guid.parse(Guid.EMPTY),
      einladungsId: Guid.parse(Guid.EMPTY),
      formType: { id: Number(Teilnahmeform.Default), type: "" },
      statusType: { id: Number(EinladungsrückmeldungStatus.Keine), type: "" },
      verhinderungsgrundType: { id: Verhinderungsgrund.Keine, type: "" },
      rückmeldungTeilnahmeForm: Teilnahmeform.Präsenz,
      rückmeldungStatus: EinladungsrückmeldungStatus.Zusage,
      rückmeldungVerhinderungsgrund: Verhinderungsgrund.Keine,
      kommentar: "",

      sent: false,
      formValid: null,
      showMessage: false,

      currentRückmeldung: {
        id: Guid.createEmpty().toString(),
        userId: Guid.createEmpty().toString(),
        participationForm: Teilnahmeform.Präsenz,
        response: EinladungsrückmeldungStatus.Zusage,
        declineReason: Verhinderungsgrund.Keine,
        responseComment: "",
        einladungId: Guid.createEmpty().toString()
      },

      userPermissions: [],
      sitzungsName: ''
    };
  }

  async componentDidMount() {
    await msalInstance.initialize();
    this.setState({ isLoading: true });
    const {
      location: { search },
    } = this.props;
    const { einladungsid, sitzungsid, preselectedStatus } = querystring.parse(search);

    let result: { id: number; type: string } | undefined =
      rückmeldungStatusType.find((z) => z.id === Number(EinladungsrückmeldungStatus.Zusage));
    if (!result) {
      result = this.state.statusType;
    }

    this.setState(
      {
        ...this.state,
        sitzungsId: Guid.parse(sitzungsid!.toString()),
        einladungsId: Guid.parse(einladungsid!.toString()),
        statusType: result,
      },
      this.loadEinladungsrückmeldungsstatusAsync
    );

  }

  teilnahmeFormOnChange = (e: any, v) => {
    this.setState({ showErrorMessage: false })
    let participationForm = parseInt(v.optionValue);
    this.setState({
      formType: {
        id: participationForm,
        type: v.optionText
      }
    });
    let result: { id: number; type: string } | undefined =
      teilnahmeFormType.find((z) => z.id === participationForm);
    if (result !== undefined) {
      this.setState({ formType: result });
      this.setState({
        currentRückmeldung: {
          ...this.state.currentRückmeldung,
          participationForm: participationForm,
        },
      });
    }
  };

  statusTypeOnChange = (e: any, v) => {
    this.setState({ showErrorMessage: false })
    let response = parseInt(v.optionValue);
    this.setState({
      statusType: {
        id: response,
        type: v.optionText
      }
    });
    let result: { id: number; type: string } | undefined =
      rückmeldungStatusType.find((z) => z.id === response);
    if (result !== undefined) {
      this.setState({ statusType: result });
      this.setState({
        currentRückmeldung: {
          ...this.state.currentRückmeldung,
          response: response,
        },
      });
    }
  };

  verhinderungsgrundTypeOnChange = (e: any, v) => {
    this.setState({ showErrorMessage: false })
    let declineReason = parseInt(v.optionValue);
    this.setState({
      verhinderungsgrundType: {
        id: declineReason,
        type: v.optionText
      }
    });
    let result: { id: number; type: string } | undefined =
      rückmeldungVerhinderungsgrundType.find((z) => z.id === declineReason);
    if (result !== undefined) {
      this.setState({ verhinderungsgrundType: result });
      this.setState({
        currentRückmeldung: {
          ...this.state.currentRückmeldung,
          declineReason: declineReason,
        },
      });
    }
  };

  kommentarOnChange = (e: any) => {
    let kommentar: string = e.target.value;
    this.setState({
      kommentar: kommentar,
      currentRückmeldung: {
        ...this.state.currentRückmeldung,
        responseComment: kommentar,
      },
    });
  };

  validateForm = (): boolean => {
    if (this.state.statusType.id === EinladungsrückmeldungStatus.Keine) {
      return false;
    }
    if (this.state.statusType.id === EinladungsrückmeldungStatus.Zusage && this.state.formType.id === Teilnahmeform.Default) {
      return false
    }
    if (this.state.statusType.id === EinladungsrückmeldungStatus.Absage && !this.state.verhinderungsgrundType || (this.state.verhinderungsgrundType && this.state.verhinderungsgrundType.id === -1)) {
      return false
    }
    return true;
  };

  handleOnClick = async (e: any) => {
    if (this.validateForm()) {
      const user = await this.requestAccessToken();
      if (this.context.accounts.length > 0) {
        //Only allow the update of the Einladungrückmeldung, if the user is already signed in.
        let accessToken: string = await this.requestAccessToken();

        const feedbackDataUpdate = this.state.currentRückmeldung
        feedbackDataUpdate.declineReason = this.state.verhinderungsgrundType ? this.state.verhinderungsgrundType.id : 0
        feedbackDataUpdate.responseComment = this.state.kommentar
        feedbackDataUpdate.response = this.state.statusType.id
        feedbackDataUpdate.participationForm = this.state.formType.id

        const result = await updateUserResponse(
          accessToken,
          this.state.currentRückmeldung.id!,
          feedbackDataUpdate,
          this.state.gremiumId
        );
        if (result.status === 200) {
          this.setState({ sent: true });
          // await patchAttendanceListRuckmeldung(this.state.gremiumId, this.state.anweseheitslisteId, 'anwesenheitslistenstatus', MeetingFormStepStatus.NeedsReprocessing, accessToken)
        }
      }
      this.setState({
        showMessage: true,
        formValid: true,
        showErrorMessage: true
      });
    } else {
      this.setState({
        formValid: false,
        showMessage: true,
        showErrorMessage: true
      });
    }
  };

  handleTokenAccessFailure = (error: string) => {
    alert(error);
  };

  private requestAccessToken = async () => {
    const activeAccount = this.context.instance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = this.context.instance.getAllAccounts();
    var request = {
      scopes: protectedResources.apiBR360.scopes,
      account: activeAccount || accounts[0],
    };

    const authResult = await this.context.instance.acquireTokenSilent(request);
    return authResult.accessToken;
  };

  loadEinladungsrückmeldungsstatusAsync = async () => {
    if (this.context.accounts.length > 0) {
      // Only request the access token, if a user is already signed in.
      let accessToken: string = await this.requestAccessToken();

      const resp = await getEinladungsadressatDto(accessToken, this.state.sitzungsId)
      const responseSitzung = await getSitzungDetails(this.state.sitzungsId!.toString(), accessToken)
      if (responseSitzung.data.start < new Date()) {
        this.setState({ sitzungenStarted: true })
      }
      this.setState({ sitzungsName: responseSitzung.data.name, gremiumId: responseSitzung.data.gremiumId }, async () => {
        const resp = await getAttendanceListRuckmeldung(this.state.sitzungsId.toString(), this.state.gremiumId, accessToken)
        if (resp.data.length > 0) {
          this.setState({ anweseheitslisteId: resp.data[0].id })
        }
      })

      if (resp.status === StatusCodes.OK && resp.data) {
        this.setState({ currentRückmeldung: resp.data });
        let currentForm: { id: Teilnahmeform; type: string } =
          teilnahmeFormType.find((z) => z.id === responseSitzung.data.sitzungsformat)!!;
        let currentVerhinderungsgrund: {
          id: Verhinderungsgrund;
          type: string;
        } = rückmeldungVerhinderungsgrundType.find(
          (z) => z.id === resp.data.declineReason
        )!!;
        this.setState({
          formType: currentForm,
          verhinderungsgrundType: currentVerhinderungsgrund,
        });
        this.setState({ isLoading: false, isInvited: true });
      }
      else {
        this.setState({ isLoading: false, isInvited: false })
      }
    }
  };

  handleLogout = async () => {
    const instance = this.context.instance;

    this.setState({ isInvited: undefined });
    instance.logoutPopup()
  }

  render = () => {
    const isAuthenticated = this.context.accounts.length > 0;
    return (
      <div className="body">
        {isAuthenticated && <Button appearance="transparent" style={{ position: "absolute", right: 0 }} onClick={this.handleLogout}>Ausloggen</Button>}
        <div className="title-container">
          <h1 className="title">
            <img className="logo-style" src={Logo} /> Betriebsrat360 | Ihr
            digitaler 360° Assistent bei der BR-Arbeit
          </h1>
        </div>
        {this.state.isLoading &&
          <div className="spinner-center">
            <Spinner
              className="loader"
              label={LoaderMessage}
              labelPosition="below"
            />
          </div>
        }
        {isAuthenticated &&
          (!this.state.isLoading && (
            <div className="main-content">

              <div className="page-content margins-sitzung page-container">
                <div className="main-center">

                  {this.state.sitzungenStarted ? (
                    <span className="sitzung-started">
                      Die Sitzung hat bereits begonnen. Sie können hier nichts
                      mehr ändern.
                    </span>
                  ) : this.state.isInvited !== undefined && (!this.state.isInvited ? <span className="sitzung-started">Sie sind zu diesem Treffen nicht eingeladen.</span> : (<>

                    <div className="konfig-box">
                      <MessageBar
                        icon={
                          <span
                            style={{ fontSize: "40px" }}
                          ><InfoIcon /></span>
                        }
                        intent="info"
                        style={{
                          width: "90%",
                          textAlign: "left",
                          padding: "20px 30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >Hier können Sie Ihrem Sitzungsorganisator zurückmelden, ob und in welcher Form Sie an der Sitzung teilnehmen werden (online oder in Präsenz). Bei einer Absage wählen Sie bitte den zutreffenden Verhinderungsgrund aus dem Dropdown-Menü aus. Denken Sie bitte daran: Nur bei einem echten Verhinderungsgrund darf ein Ersatzmitglied eingeladen werden. Wenn keiner der genannten Gründe vorliegt, können Sie das Freitextfeld nutzen, um dem Vorsitzenden mitzuteilen, weshalb Sie nicht teilnehmen können.</MessageBar>
                    </div>
                    <div className="form-style">
                      <h3 className="text-left">
                        Rückmeldung zur Sitzung:
                        <span style={{ fontWeight: "bold", marginLeft: "6px" }}>
                          {" "}
                          {this.state.sitzungsName}
                        </span>
                      </h3>
                      <Field
                        label="Status"
                        id="status"
                        required
                      ><Dropdown
                        style={DROPDOWN_STYLE}
                        defaultValue={this.state.statusType.type}
                        onOptionSelect={this.statusTypeOnChange}>
                          {rückmeldungStatusType.map((type, i) => <Option key={i} value={i.toString()}>{type.type}</Option>)}
                        </Dropdown></Field>
                      {this.state.statusType.id ===
                        EinladungsrückmeldungStatus.Zusage && (
                          <Field
                            label="Form der Teilnahme"
                            // name="teilnahmeForm"
                            id="teilnahmeForm"
                            validationMessage={this.state.formType.id === Teilnahmeform.Default ? RequiredFieldErrorMessage : ''}
                            validationState={this.state.formType.id === Teilnahmeform.Default ? "error" : "none"}
                            required
                          >    <Dropdown
                            style={DROPDOWN_STYLE}
                            defaultValue={this.state.formType.type}
                            onOptionSelect={this.teilnahmeFormOnChange}>
                              {teilnahmeFormType.map((type, i) => <Option key={i} value={i.toString()}>{type.type}</Option>)}
                            </Dropdown></Field>
                        )}
                      {this.state.statusType.id ===
                        EinladungsrückmeldungStatus.Absage && (

                          <Field
                            label={<>Verhinderungsgrund{" "}
                              <Tooltip
                                relationship="label"
                                content="Im Dropdownmenü finden Sie die klassischen Verhinderungsgründe, bei denen grundsätzlich ein Ersatzmitglied nachgeladen werden muss. Achtung: Zu viel Arbeit ist grundsätzlich kein Verhinderungsgrund. Einzelheiten können in der Geschäftsordnung geregelt werden."
                              ><span><InfoIcon /></span></Tooltip></>}
                            id="verhinderungsgrund"
                            validationMessage={!this.state.verhinderungsgrundType ? RequiredFieldErrorMessage : this.state.verhinderungsgrundType.id === -1 ? RequiredFieldErrorMessage : ''}
                            validationState={!this.state.verhinderungsgrundType ? "error" : this.state.verhinderungsgrundType.id === -1 ? "error" : "none"}
                            required
                            style={{
                              marginTop: "7px",
                            }}
                          ><Dropdown
                            style={DROPDOWN_STYLE}
                            value={this.state.verhinderungsgrundType ? this.state.verhinderungsgrundType.type : ''}
                            onOptionSelect={this.verhinderungsgrundTypeOnChange}>
                              {rückmeldungVerhinderungsgrundType.map((type) => <Option key={type.id} value={type.id.toString()}>{type.type}</Option>)}
                            </Dropdown></Field>
                        )}
                      <Field
                        label="Kommentar"
                        id="kommentar"
                      ><Textarea
                          style={{
                            width: "100%",
                            height: "100px",
                          }}
                          value={this.state.currentRückmeldung?.responseComment}
                          onChange={this.kommentarOnChange}
                        /></Field>

                      <Field>
                        <Button
                          appearance="primary"
                          onClick={this.handleOnClick}
                        >Absenden</Button></Field>

                      <Field
                        hidden={!this.state.showMessage}
                        validationMessage={this.state.showErrorMessage ?
                          (!this.state.formValid ? "Unvollständige Angaben." : this.state.sent ? "Vielen Dank! Deine Rückmeldung wurde übermittelt." :
                            "Die Rückmeldung konnte nicht gesendet werden") : ''
                        }
                        validationState={this.state.showErrorMessage ? (!this.state.formValid ? "error" : (this.state.sent && this.state.showMessage) ? "success" :
                          "warning") : "none"}
                      ></Field>
                    </div></>)
                  )}
                </div>
              </div>
            </div>
          ))}
        {!isAuthenticated && !this.state.isLoading && (
          <div className="login-container">
            <div className="centered-content">
              <p>
                Sie sind noch nicht angemeldet, bitte loggen Sie sich ein, um eine
                Einladungsrückmeldung zu geben.
              </p>
              <BrowserSignInButton />
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  };
}

export default Einladungsrueckmeldung;
