import { Combobox, Field } from "@fluentui/react-components";
import { Component } from "react";

class CustomCombobox extends Component<{
  defaultValue: string;
  onSelectFunction: any;
  options: JSX.Element[];
  userHasUpdatePermission: boolean;
}> {
  render() {
    const { defaultValue, onSelectFunction, options, userHasUpdatePermission } =
      this.props;

    return (
      <Field style={{ width: "100%" }} validationState={defaultValue === "" ? "error" : "none"}>
        <Combobox
          defaultValue={defaultValue}
          onOptionSelect={(e, data) => {
            onSelectFunction(data);
          }}
          freeform
          placeholder="Suche nach Person"
          listbox={{ style: { height: "250px", width: "500px" } }}
          style={{
            width: "100%",
          }}
          disabled={!userHasUpdatePermission}
        >
          {options}
        </Combobox>
      </Field>
    );
  }
}

export default CustomCombobox;
